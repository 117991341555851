import MenuResType from "../types/res/MenuResType";
import MenuButton from "./MenuButton";

interface MenuProps {
	menus: MenuResType[];
	openMenuView: (parentMenu: MenuResType) => void;
}

function Menu({
	menus,
	openMenuView
}: MenuProps): JSX.Element {
	return (
		<>
			<div id="menu-container" className="container">
				<h5 className="mb-3">Menu</h5>
				<div className="row">
					{menus.map((menu, index) => (
						<MenuButton
							key={Number(menu.id)}
							menu={menu}
							index={index}
							openMenuView={openMenuView}
						/>
					))}
				</div>
			</div>
		</>
	);
}

export default Menu;

import { useEffect, useState } from "react";
import ProductApi from "../../api/ProductApi";
import ProductCatApi from "../../api/ProductCatApi";
import SupplierApi from "../../api/SupplierApi";
import TaxApi from "../../api/TaxApi";
import TaxCatApi from "../../api/TaxCatApi";
import UnitApi from "../../api/UnitApi";
import MessageDialog from "../../dialogs/MessageDialog";
import ProductCatHelper from "../../helpers/ProductCatHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import SupplierHelper from "../../helpers/SupplierHelper";
import TaxCatHelper from "../../helpers/TaxCatHelper";
import TaxHelper from "../../helpers/TaxHelper";
import UnitHelper from "../../helpers/UnitHelper";
import DropDownItemType from "../../types/DropDownItemType";
import OpeningStockType from "../../types/OpeningStockType";
import ProductReqHoDto from "../../types/req/ProductReqHoDto";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import ProductCatListResType from "../../types/res/list/ProductCatListResType";
import SupplierListResType from "../../types/res/list/SupplierListResType";
import TaxCatListResType from "../../types/res/list/TaxCatListResType";
import TaxListResType from "../../types/res/list/TaxListResType";
import UnitListResType from "../../types/res/list/UnitListResType";
import DownshiftSelect from "../DownshiftSelectV2";
import TextBox from "../controls/TextBox";
import AddOpeningStocksSv from "./AddOpeningStocksSv";
import ProductResHoDto from "../../types/res/ProductResHoDto";

interface AddProductProps {
	id?: number
}

function AddProduct({
	id
}: AddProductProps): JSX.Element {

	const [name, setName] = useState("");
	const [code, setCode] = useState("");
	const [desc, setDesc] = useState("");
	const [unitId, setUnitId] = useState<number | null>(null);
	const [hsnStr, setHsnStr] = useState("");
	const [rawPriceStr, setRawPriceStr] = useState("");
	const [polishPriceStr, setPolishPriceStr] = useState("");
	const [totalCostPriceStr, setTotalCostPriceStr] = useState("");
	const [additionalCostStr, setAdditionalCostStr] = useState("");
	const [minSalesPriceStr, setMinSalesPriceStr] = useState("");
	const [wholesaleSalesPriceStr, setWholesaleSalesPriceStr] = useState("");
	const [internetSalesPriceStr, setInternetSalesPriceStr] = useState("");
	const [agentSalesPriceStr, setAgentSalesPriceStr] = useState("");
	const [mrpStr, setMrpStr] = useState("");
	const [maxSalesPriceStr, setMaxSalesPriceStr] = useState("");
	const [discountStr, setDiscountStr] = useState("");
	const [productCatId, setProductCatId] = useState<number | null>(null);
	const [taxCatId, setTaxCatId] = useState<number | null>(null);
	const [taxId, setTaxId] = useState<number | null>(null);
	const [note, setNote] = useState("");
	const [isSold, setIsSold] = useState(true);
	const [isMade, setIsMade] = useState(false);
	const [minQtyStr, setMinQtyStr] = useState("");
	const [reorderQtyStr, setReorderQtyStr] = useState("");
	const [rawSupplierId, setRawSupplierId] = useState<number | null>(null);
	const [polishedBySupplierId, setPolishedBySupplierId] = useState<number | null>(null);
	const [weightStr, setWeightStr] = useState("");
	const [isRatePerWeight, setIsRatePerWeight] = useState(false);
	const [ratePerWeightStr, setRatePerWeightStr] = useState("");

	const [openingStocks, setOpeningStocks] = useState<OpeningStockType[]>([]);
	//
	const [unitListResArr, setUnitListArr] = useState<UnitListResType[]>([]);
	const [unitListResArrForDd, setUnitListArrForDd] = useState<DropDownItemType[]>([]);
	const [unitListResMap, setUnitListResMap] = useState<Map<number, UnitListResType>>(new Map());

	const [supplierListResArr, setSupplierListArr] = useState<SupplierListResType[]>([]);
	const [supplierListResArrForDd, setSupplierListArrForDd] = useState<DropDownItemType[]>([]);
	const [supplierListResMap, setSupplierListResMap] = useState<Map<number, SupplierListResType>>(new Map());

	const [productCatListResArr, setProductCatListArr] = useState<ProductCatListResType[]>([]);
	const [productCatListResArrForDd, setProductCatListArrForDd] = useState<DropDownItemType[]>([]);
	const [productCatListResMap, setProductCatListResMap] = useState<Map<number, ProductCatListResType>>(new Map());

	const [taxCatListResArr, setTaxCatListArr] = useState<TaxCatListResType[]>([]);
	const [taxCatListResArrForDd, setTaxCatListArrForDd] = useState<DropDownItemType[]>([]);
	const [taxCatListResMap, setTaxCatListResMap] = useState<Map<number, TaxCatListResType>>(new Map());

	const [taxListResArr, setTaxListArr] = useState<TaxListResType[]>([]);
	const [taxListResArrForDd, setTaxListArrForDd] = useState<DropDownItemType[]>([]);
	const [taxListResMap, setTaxListResMap] = useState<Map<number, TaxListResType>>(new Map());
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	//
	useEffect(() => {
		async function process() {

			UnitApi.getUnits().then((response) => {
				if (response.isSuccess) {
					setUnitListArr(response.items!);
					setUnitListArrForDd(UnitHelper.getUnitsForDd(response.items!));
					setUnitListResMap(UnitHelper.getUnitMap(response.items!));
				}
			});

			SupplierApi.getSuppliers().then((response) => {
				if (response.isSuccess) {
					setSupplierListArr(response.items!);
					setSupplierListArrForDd(SupplierHelper.getSupplierListResArrForDd(response.items!));
					setSupplierListResMap(SupplierHelper.getSupplierListResMap(response.items!));
				}
			});

			ProductCatApi.getProductCategorys().then((response) => {
				if (response.isSuccess) {
					setProductCatListArr(response.items!);
					setProductCatListArrForDd(ProductCatHelper.getProductCatListForDd(response.items!));
					setProductCatListResMap(ProductCatHelper.getProductCatMap(response.items!));
				}
			});

			TaxCatApi.getTaxCats().then((response) => {
				if (response.isSuccess) {
					setTaxCatListArr(response.items!);
					setTaxCatListArrForDd(TaxCatHelper.getTaxCatsForDd(response.items!));
					setTaxCatListResMap(TaxCatHelper.getTaxCatListResMap(response.items!));
				}
			});

			TaxApi.getTaxes().then((response) => {
				if (response.isSuccess) {
					setTaxListArr(response.items!);
					setTaxListArrForDd(TaxHelper.getTaxListForDd(response.items!));
					setTaxListResMap(TaxHelper.getTaxMap(response.items!));
				}
			});

			if (id !== undefined && id !== null) {
				fillInUi(await ProductApi.getProduct(id));
			}

		}
		process();
	}, []);

	const handleAddItem = (addOpeningStock: OpeningStockType) => {
		setOpeningStocks([...openingStocks, addOpeningStock]);
	}

	const handleUpdateItem = (index: number, openingStock: OpeningStockType) => {
		let openingStocksLoc = [...openingStocks];
		openingStocksLoc[index] = openingStock;
		setOpeningStocks(openingStocksLoc);
	}

	const handleDeleteItem = (index: number) => {
		let openingStocksLoc = [...openingStocks];
		openingStocksLoc.splice(index, 1);
		setOpeningStocks(openingStocksLoc);
	}

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let product: ProductReqHoDto = {
			productReqDto: {
				name: name,
				code: code,
				desc: desc,
				unitId: unitId === -1 ? null : unitId,
				hsn: hsnStr == null || hsnStr.trim() === "" ? null : parseInt(hsnStr),
				rawPrice: rawPriceStr == null || rawPriceStr.trim() === "" ? null : parseFloat(rawPriceStr),
				polishPrice: polishPriceStr == null || polishPriceStr.trim() === "" ? null : parseFloat(polishPriceStr),
				totalCostPrice: totalCostPriceStr == null || totalCostPriceStr.trim() === "" ? null : parseFloat(totalCostPriceStr),
				additionalCost: additionalCostStr == null || additionalCostStr.trim() === "" ? null : parseFloat(additionalCostStr),
				minSalesPrice: minSalesPriceStr == null || minSalesPriceStr.trim() === "" ? null : parseFloat(minSalesPriceStr),
				wholesaleSalesPrice: wholesaleSalesPriceStr == null || wholesaleSalesPriceStr.trim() === "" ? null : parseFloat(wholesaleSalesPriceStr),
				internetSalesPrice: internetSalesPriceStr == null || internetSalesPriceStr.trim() === "" ? null : parseFloat(internetSalesPriceStr),
				agentSalesPrice: agentSalesPriceStr == null || agentSalesPriceStr.trim() === "" ? null : parseFloat(agentSalesPriceStr),
				mrp: mrpStr == null || mrpStr.trim() === "" ? null : parseFloat(mrpStr),
				maxSalesPrice: maxSalesPriceStr == null || maxSalesPriceStr.trim() === "" ? null : parseFloat(maxSalesPriceStr),
				discount: discountStr == null || discountStr.trim() === "" ? null : parseFloat(discountStr),
				catId: productCatId === -1 ? null : productCatId,
				taxCatId: taxCatId === -1 ? null : taxCatId,
				taxId: taxId === -1 ? null : taxId,
				note: note,
				isSold: isSold,
				isMade: isMade,
				minQty: minQtyStr == null || minQtyStr.trim() === "" ? null : parseFloat(minQtyStr),
				reorderQty: reorderQtyStr == null || reorderQtyStr.trim() === "" ? null : parseFloat(reorderQtyStr),
				rawSupplierId: rawSupplierId === -1 ? null : rawSupplierId,
				polishedBySupplierId: polishedBySupplierId === -1 ? null : polishedBySupplierId,
				weight: weightStr == null || weightStr.trim() === "" ? null : parseFloat(weightStr),
				isRatePerWeight: isRatePerWeight,
				ratePerWeight: ratePerWeightStr == null || ratePerWeightStr.trim() === "" ? null : parseFloat(ratePerWeightStr)
			},
			openingStockReqDtos: openingStocks
		}

		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await ProductApi.createProduct(product);
		}
		else {
			res = await ProductApi.updateProduct(id, product);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}
	}

	const fillInUi = (productResHoDto: ProductResHoDto) => {

		let productResDto = productResHoDto.productResDto;

		setName(productResDto.name);
		setCode(productResDto.code == null ? "" : productResDto.code);
		setCode(productResDto.desc);
		if (productResDto.unitId !== null) {
			setUnitId(productResDto.unitId);
		}
		if (productResDto.hsn !== null) {
			setHsnStr(productResDto.hsn.toString());
		}
		if (productResDto.rawPrice !== null) {
			setRawPriceStr(productResDto.rawPrice.toString());
		}
		if (productResDto.polishPrice !== null) {
			setPolishPriceStr(productResDto.polishPrice.toString());
		}
		if (productResDto.totalCostPrice !== null) {
			setTotalCostPriceStr(productResDto.totalCostPrice.toString());
		}
		if (productResDto.additionalCost !== null) {
			setAdditionalCostStr(productResDto.additionalCost.toString());
		}
		if (productResDto.minSalesPrice !== null) {
			setMinSalesPriceStr(productResDto.minSalesPrice.toString());
		}
		if (productResDto.wholesaleSalesPrice !== null) {
			setWholesaleSalesPriceStr(productResDto.wholesaleSalesPrice.toString());
		}
		if (productResDto.mrp !== null) {
			setMrpStr(productResDto.mrp.toString());
		}
		if (productResDto.internetSalesPrice !== null) {
			setInternetSalesPriceStr(productResDto.internetSalesPrice.toString());
		}
		if (productResDto.maxSalesPrice !== null) {
			setMaxSalesPriceStr(productResDto.maxSalesPrice.toString());
		}
		if (productResDto.discount !== null) {
			setDiscountStr(productResDto.discount.toString());
		}
		if (productResDto.catId !== null) {
			setProductCatId(productResDto.catId);
		}
		if (productResDto.taxCatId !== null) {
			setTaxCatId(productResDto.taxCatId);
		}
		setNote(productResDto.note);
		setIsSold(productResDto.isSold);
		setIsMade(productResDto.isMade);
		if (productResDto.minQty !== null) {
			setMinQtyStr(productResDto.minQty.toString());
		}
		if (productResDto.reorderQty !== null) {
			setReorderQtyStr(productResDto.reorderQty.toString());
		}
		if (productResDto.rawSupplierId !== null) {
			setRawSupplierId(productResDto.rawSupplierId);
		}
		if (productResDto.polishedBySupplierId !== null) {
			setPolishedBySupplierId(productResDto.polishedBySupplierId);
		}
		if (productResDto.weight !== null) {
			setWeightStr(productResDto.weight.toString());
		}

		setOpeningStocks(productResHoDto.openingStockResDtos!);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
		setCode("");
		setDesc("");
		setUnitId(-1);
		setHsnStr("");
		setRawPriceStr("");
		setPolishPriceStr("");
		setTotalCostPriceStr("");
		setAdditionalCostStr("");
		setMinSalesPriceStr("");
		setWholesaleSalesPriceStr("");
		setInternetSalesPriceStr("");
		setAgentSalesPriceStr("");
		setMrpStr("");
		setMaxSalesPriceStr("");
		setDiscountStr("");
		setProductCatId(-1);
		setTaxCatId(-1);
		setTaxId(-1);
		setNote("");
		setIsSold(true);
		setIsMade(false);
		setMinQtyStr("");
		setReorderQtyStr("");
		setRawSupplierId(-1);
		setPolishedBySupplierId(-1);
		setWeightStr("");
		setIsRatePerWeight(false);
		setRatePerWeightStr("");

		setOpeningStocks([]);
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			code === undefined
			|| code === null
			|| code === ""
		) {
			setMessageDialogMessage("Invalid code");
			setShowMessageDialog(true);
			return false;
		}

		if (
			unitId != null
			&& unitListResMap.get(unitId) == null
		) {
			setMessageDialogMessage("Invalid unit");
			setShowMessageDialog(true);
			return false;
		}

		if (
			hsnStr != null
			&& hsnStr !== ""
			&& !StringHelper2.isInt(hsnStr.trim())
		) {
			setMessageDialogMessage("Invalid HSN");
			setShowMessageDialog(true);
			return false;
		}

		if (
			rawPriceStr != null
			&& rawPriceStr !== ""
			&& !StringHelper2.isNum(rawPriceStr.trim())
		) {
			setMessageDialogMessage("Invalid raw purchase price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			polishPriceStr != null
			&& polishPriceStr !== ""
			&& !StringHelper2.isNum(polishPriceStr.trim())
		) {
			setMessageDialogMessage("Invalid polish price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			totalCostPriceStr != null
			&& totalCostPriceStr !== ""
			&& !StringHelper2.isNum(totalCostPriceStr.trim())
		) {
			setMessageDialogMessage("Invalid cost price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			additionalCostStr != null
			&& additionalCostStr !== ""
			&& !StringHelper2.isNum(additionalCostStr.trim())
		) {
			setMessageDialogMessage("Invalid additional cost");
			setShowMessageDialog(true);
			return false;
		}

		if (
			minSalesPriceStr != null
			&& minSalesPriceStr !== ""
			&& !StringHelper2.isNum(minSalesPriceStr.trim())
		) {
			setMessageDialogMessage("Invalid minimum sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			wholesaleSalesPriceStr != null
			&& wholesaleSalesPriceStr !== ""
			&& !StringHelper2.isNum(wholesaleSalesPriceStr.trim())
		) {
			setMessageDialogMessage("Invalid wholesale sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			internetSalesPriceStr != null
			&& internetSalesPriceStr !== ""
			&& !StringHelper2.isNum(internetSalesPriceStr.trim())
		) {
			setMessageDialogMessage("Invalid internet sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (agentSalesPriceStr != null
			&& agentSalesPriceStr !== ""
			&& !StringHelper2.isNum(agentSalesPriceStr.trim())
		) {
			setMessageDialogMessage("Invalid agent sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			mrpStr != null
			&& mrpStr !== ""
			&& !StringHelper2.isNum(mrpStr.trim())
		) {
			setMessageDialogMessage("Invalid MRP");
			setShowMessageDialog(true);
			return false;
		}


		if (
			maxSalesPriceStr != null
			&& maxSalesPriceStr !== ""
			&& !StringHelper2.isNum(maxSalesPriceStr.trim())
		) {
			setMessageDialogMessage("Invalid max sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			discountStr != null
			&& discountStr !== ""
			&& !StringHelper2.isNum(discountStr.trim())
		) {
			setMessageDialogMessage("Invalid discount rate");
			setShowMessageDialog(true);
			return false;
		}

		if (
			productCatId != null
			&& productCatListResMap.get(productCatId) == null
		) {
			setMessageDialogMessage("Invalid product category");
			setShowMessageDialog(true);
			return false;
		}

		if (
			taxCatId != null
			&& taxCatListResMap.get(taxCatId) == null
		) {
			setMessageDialogMessage("Invalid tax category");
			setShowMessageDialog(true);
			return false;
		}


		if (
			taxId != null
			&& taxListResMap.get(taxId) == null
		) {
			setMessageDialogMessage("Invalid tax");
			setShowMessageDialog(true);
			return false;
		}

		if (
			minQtyStr != null
			&& minQtyStr !== ""
			&& !StringHelper2.isNum(minQtyStr.trim())
		) {
			setMessageDialogMessage("Invalid minimum quantity");
			setShowMessageDialog(true);
			return false;
		}

		if (
			reorderQtyStr != null
			&& reorderQtyStr !== ""
			&& !StringHelper2.isNum(reorderQtyStr.trim())
		) {
			setMessageDialogMessage("Invalid reorder quantity");
			setShowMessageDialog(true);
			return false;
		}

		if (
			rawSupplierId != null
			&& supplierListResMap.get(rawSupplierId) == null
		) {
			setMessageDialogMessage("Invalid raw supplier");
			setShowMessageDialog(true);
			return false;
		}

		if (
			polishedBySupplierId != null
			&& supplierListResMap.get(polishedBySupplierId) == null
		) {
			setMessageDialogMessage("Invalid polished by supplier");
			setShowMessageDialog(true);
			return false;
		}

		if (
			weightStr != null
			&& weightStr !== ""
			&& !StringHelper2.isNum(weightStr.trim())
		) {
			setMessageDialogMessage("Invalid weight");
			setShowMessageDialog(true);
			return false;
		}

		if (ratePerWeightStr != null
			&& ratePerWeightStr !== ""
			&& !StringHelper2.isNum(ratePerWeightStr.trim())
		) {
			setMessageDialogMessage("Invalid rate per weight");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">
						<TextBox label="Name" value={name} onChange={setName} />
						<TextBox label="Code" value={code} onChange={setCode} />
						<TextBox label="Description" value={desc} onChange={setDesc} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbUnit" className="col-form-label">Unit</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCompanyProfile"}
									value={unitId != null && unitListResMap?.get(unitId) != null ? UnitHelper.getUnitForDd(unitListResMap?.get(unitId)!) : null}
									onChange={setUnitId}
									options={unitListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox label="HSN" value={hsnStr} onChange={setHsnStr} />
						<TextBox label="Raw Price" value={rawPriceStr} onChange={setRawPriceStr} />
						<TextBox label="Polish Price" value={polishPriceStr} onChange={setPolishPriceStr} />
						<TextBox label="Total Cost Price" value={totalCostPriceStr} onChange={setTotalCostPriceStr} />
						<TextBox label="Additional Cost" value={additionalCostStr} onChange={setAdditionalCostStr} />
						<TextBox label="Min. Sales Price" value={minSalesPriceStr} onChange={setMinSalesPriceStr} />
						<TextBox label="Wholesale Sales Price" value={wholesaleSalesPriceStr} onChange={setWholesaleSalesPriceStr} />
						<TextBox label="Internet Sales Price" value={internetSalesPriceStr} onChange={setInternetSalesPriceStr} />
						<TextBox label="Agent Sales Price" value={agentSalesPriceStr} onChange={setAgentSalesPriceStr} />
						<TextBox label="MRP" value={mrpStr} onChange={setMrpStr} />
						<TextBox label="Max. Sales Price" value={maxSalesPriceStr} onChange={setMaxSalesPriceStr} />
						<TextBox label="Discount" value={discountStr} onChange={setDiscountStr} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbProductCat" className="col-form-label">ProductCat</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbProductCat"}
									value={productCatId != null && productCatListResMap?.get(productCatId) != null ? ProductCatHelper.getProductCatForDd(productCatListResMap?.get(productCatId)!) : null}
									onChange={setProductCatId}
									options={productCatListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTaxCat" className="col-form-label">TaxCat</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTaxCat"}
									value={taxCatId != null && taxCatListResMap?.get(taxCatId) != null ? TaxCatHelper.getTaxCatListResForDd(taxCatListResMap?.get(taxCatId)!) : null}
									onChange={setTaxCatId}
									options={taxCatListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>


						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTax" className="col-form-label">Tax</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTax"}
									value={taxId != null && taxListResMap?.get(taxId) != null ? TaxHelper.getTaxForDd(taxListResMap?.get(taxId)!) : null}
									onChange={setTaxId}
									options={taxListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox label="Note" value={note} onChange={setNote} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-10 offset-sm-2">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										checked={isSold}
										id="flexCheckDefault"
										onChange={(e) => setIsSold(e.target.checked)}
									/>
									<label className="form-check-label" htmlFor="flexCheckDefault">
										Is Sold
									</label>
								</div>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-10 offset-sm-2">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										checked={isMade}
										id="flexCheckDefault"
										onChange={(e) => setIsMade(e.target.checked)}
									/>
									<label className="form-check-label" htmlFor="flexCheckDefault">
										Is Made
									</label>
								</div>
							</div>
						</div>

						<TextBox label="Min. Quantity" value={minQtyStr} onChange={setMinQtyStr} />
						<TextBox label="Reorder Quantity" value={reorderQtyStr} onChange={setReorderQtyStr} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbRawSupplier" className="col-form-label">Raw Supplier</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbRawSupplier"}
									value={rawSupplierId != null && supplierListResMap?.get(rawSupplierId) != null ? SupplierHelper.getSupplierListResForDd(supplierListResMap?.get(rawSupplierId)!) : null}
									onChange={setRawSupplierId}
									options={supplierListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbPolishedBySupplier" className="col-form-label">Polished By Supplier</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbPolishedBySupplier"}
									value={polishedBySupplierId != null && supplierListResMap?.get(polishedBySupplierId) != null ? SupplierHelper.getSupplierListResForDd(supplierListResMap?.get(polishedBySupplierId)!) : null}
									onChange={setPolishedBySupplierId}
									options={supplierListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox label="Weight" value={weightStr} onChange={setWeightStr} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-10 offset-sm-2">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										checked={isRatePerWeight}
										id="chkIsRatePerWeight"
										onChange={(e) => setIsRatePerWeight(e.target.checked)}
									/>
									<label className="form-check-label" htmlFor="flexCheckDefault">
										Is Rate per Weight?
									</label>
								</div>
							</div>
						</div>

						<TextBox label="Rate per Weight" value={ratePerWeightStr} onChange={setRatePerWeightStr} />

					</div>

					<AddOpeningStocksSv
						handleAddItem={handleAddItem}
						handleUpdateItem={handleUpdateItem}
						handleDeleteItem={handleDeleteItem}
						addOpeningStocks={openingStocks}
					/>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddProduct;

import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import UnitApi from "../../api/UnitApi";
import RowType from "../../types/RowType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";

function ListUnit(): JSX.Element {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const colNames = [
		"Name",
		"Code",
		"Is Derived?",
		"Base Unit"
	];

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			let res = await UnitApi.getUnits();
			if (res.isSuccess) {

				let dataToReturn: RowType[] = [];
				for (let i = 0; i < res.items?.length!; i++) {

					let row = res.items![i];

					let oneRowArray = [];
					oneRowArray.push(row.name);
					oneRowArray.push(row.code);
					oneRowArray.push(row.isDerived ? "YES" : "NO");
					let baseUnitNameStr = "";
					if (row.isDerived) {
						if (row.operatorId === 1) {
							baseUnitNameStr = "1 " + row.baseUnitName + " / " + row.num;
						} else if (row.operatorId === 2) {
							baseUnitNameStr = row.num + " " + " x " + row.baseUnitName;
						}
					}
					oneRowArray.push(baseUnitNameStr);
					dataToReturn.push({
						id: row.id,
						data: oneRowArray
					});
				};

				setRows(dataToReturn);
			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}

		}
		process();
	}, []);

	const handleClickAdd = () => {
		gv.menuHelper.openAddUnit();
	};

	const handleClickEdit = () => {
		console.log("edit clicked");
	};

	const handleClickDelete = () => {
		console.log("handleClickDelete");
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	return (
		<>
			<ListViewButtonBar
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>
			) : null}
		</>
	);
}

export default ListUnit;

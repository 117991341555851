import { useState } from "react";
import OpeningStockType from "../../types/OpeningStockType";
import useConfirm from "../../dialogs/ConfirmDialog";

interface AddOpeningStocksTableProps {
	addOpeningStocks: Array<OpeningStockType>;
	handleClickEditParent: (index: number) => void;
	handleClickDeleteParent: (index: number) => void;
}

function AddOpeningStocksTable({
	addOpeningStocks,
	handleClickEditParent,
	handleClickDeleteParent
}: AddOpeningStocksTableProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();

	const handleClickEdit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

		event.preventDefault();

		if (
			selectedIndex == null
			|| selectedIndex < 0
			|| selectedIndex >= addOpeningStocks.length
		) {
			setMessageDialogMessage("Select a row to edit");
			setShowMessageDialog(true);
			return false;
		}

		handleClickEditParent(selectedIndex);

	}

	const handleClickDelete = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

		event.preventDefault();

		if (
			selectedIndex == null
			|| selectedIndex < 0
			|| selectedIndex >= addOpeningStocks.length
		) {
			setMessageDialogMessage("Select a row to delete");
			setShowMessageDialog(true);
			return false;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		handleClickDeleteParent(selectedIndex);

	}

	return (
		<>
			<div className="flex-grow-1 mb-2">
				<button
					type="button"
					className="btn btn-primary me-2"
					onClick={handleClickEdit}
				>
					Edit
				</button>
				<button
					type="button"
					className="btn btn-primary me-2"
					onClick={handleClickDelete}
				>
					Delete
				</button>
				<button type="button" className="btn btn-primary me-2">Up</button>
				<button type="button" className="btn btn-primary me-2">Down</button>
			</div>

			<div className="flex-grow-1 mb-2 table-responsive text-nowrap">
				<table
					id="navigate"
					className="table table-hover table-bordered table-sm overflow-x-auto m-0 fixTableHead"
				>
					<thead className="bg-body-tertiary">
						<tr>
							<th scope="col">
								<input
									className="form-check-input"
									type="checkbox"
									id="chkCheckAll"
								/>
							</th>
							<th scope="col">Sr. No.</th>
							<th scope="col">Quantity</th>
							<th scope="col">Rate</th>
							<th scope="col">Amt</th>
							<th scope="col">Raw Price</th>
							<th scope="col">Polish Price</th>
							<th scope="col">Total Cost Price</th>
							<th scope="col">Additional Cost</th>
							<th scope="col">Min. Sales Price</th>
							<th scope="col">Wholesale Sales Price</th>
							<th scope="col">Internet Sales Price</th>
							<th scope="col">Agent Sales Price</th>
							<th scope="col">MRP</th>
							<th scope="col">Max. Sales Price</th>
							<th scope="col">Weight</th>
							<th scope="col">Is Rate per Weight</th>
							<th scope="col">Rate per Weight</th>
							<th scope="col">Purchase Date</th>

						</tr>
					</thead>
					<tbody className="">
						{addOpeningStocks.map((addOpeningStockItem, i) => (
							<tr key={i} onClick={(e) => setSelectedIndex(i)}>
								<td>
									<div>
										<input
											className="form-check-input"
											type="checkbox"
											value=""
										/>
									</div>
								</td>
								<td tabIndex={0}>
									<div>{i + 1}</div>
								</td>
								<td tabIndex={0}><div>{addOpeningStockItem.qty.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.rate.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.derivedAmt?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.rawPrice?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.polishPrice?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.totalCostPrice?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.additionalCost?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.minSalesPrice?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.wholesaleSalesPrice?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.internetSalesPrice?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.agentSalesPrice?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.mrp?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.maxSalesPrice?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.weight?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.isRatePerWeight ? "YES" : "NO"}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.ratePerWeight?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addOpeningStockItem.purchaseDate?.toString() ?? ""}</div></td>
							</tr>
						))}
					</tbody>
					<tfoot className="bg-body-tertiary">
						<tr>
							<th scope="row">Total</th>
							<th>{addOpeningStocks.length}</th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
					</tfoot>
				</table>
			</div>
		</>
	);
}

export default AddOpeningStocksTable;

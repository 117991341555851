import { ReactNode, createContext, useContext } from "react";
import MenuHelper from "./helpers/MenuHelper";

export interface GvContextType {
	menuHelper: MenuHelper;
}

const GvContext = createContext<NonNullable<GvContextType>>(null as any);

export const GvContextProvider: React.FC<{ children: ReactNode, value: GvContextType }> = ({ children, value }) => {
	return (
		<GvContext.Provider value={value}>
			{children}
		</GvContext.Provider>
	);
}

export const useGvContext = () => {
	const context = useContext(GvContext);

	if (context === null) {
		throw new Error('useMyContext must be used within a MyProvider');
	}

	return context;
}

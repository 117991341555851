import { useState } from "react";
import OpeningStockType from "../../types/OpeningStockType";
import AddOpeningStockFormSv from "./AddOpeningStockFormSv";
import AddOpeningStocksTable from "./AddOpeningStocksTable";

interface AddOpeningStocksSvProps {
	handleAddItem: (addOpeningStock: OpeningStockType) => void;
	handleUpdateItem: (index: number, openingStock: OpeningStockType) => void;
	handleDeleteItem: (index: number) => void;
	addOpeningStocks: Array<OpeningStockType>;
	id?: number;
}

function AddOpeningStocksSv({
	handleAddItem,
	handleUpdateItem,
	handleDeleteItem,
	addOpeningStocks,
	id
}: AddOpeningStocksSvProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [modeAdd, setModeAdd] = useState<boolean>(true);
	const [openingStockToEdit, setOpeningStockToEdit] = useState<OpeningStockType | null>(null);

	const handleItemChange = (openingStock: OpeningStockType) => {
		if (modeAdd) {
			handleAddItem(openingStock);
		}
		else {
			handleUpdateItem(selectedIndex, openingStock);
		}
		setModeAdd(true);
	}

	const handleClickEdit = (index: number) => {
		setModeAdd(false);
		setSelectedIndex(index);
		setOpeningStockToEdit(addOpeningStocks[index]);
	}

	const handleClickCancelEdit = () => {
		setModeAdd(false);
		setSelectedIndex(-1);
		setOpeningStockToEdit(null);
	}

	return (
		<>
			<AddOpeningStockFormSv
				handleItemChange={handleItemChange}
				openingStockToEdit={openingStockToEdit}
				handleClickCancelEdit={handleClickCancelEdit}
			/>

			<AddOpeningStocksTable
				addOpeningStocks={addOpeningStocks}
				handleClickEditParent={handleClickEdit}
				handleClickDeleteParent={handleDeleteItem}
			/>

		</>
	);
}

export default AddOpeningStocksSv;

import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import { CalcOnTypeEnum } from "../enums/CalcOnTypeEnum";
import { GetVouOthChObjectTypeEnum } from "../enums/GetVouOthChObjectTypeEnum";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import ListVouOthChargeResType from "../types/res/list/ListVouOthChargeResType";

export default class VouOthChApi {

	static async getVouOthCh(
		calOnType: CalcOnTypeEnum,
		isTaxable: boolean
	): Promise<CommonGetListType<ListVouOthChargeResType>> {

		try {
			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.VOU_OTH_CHARGE.name)
				+ getQueryString({
					returnObjectType: GetVouOthChObjectTypeEnum.VOU_OTH_CH_FOR_ESTIMATE.enumName.toUpperCase(),
					calOnType: calOnType.enumName.toUpperCase(),
					isTaxable: isTaxable
				}),
				requestOptions);

			if (response.status === 200) {
				const data: ListVouOthChargeResType[] = await response.json();
				return {
					isSuccess: true,
					items: data
				};
			}
			else if (response.status === 400) {
				const data: ErrorResType = await response.json();
				return {
					isSuccess: false,
					errorRes: data
				};
			}
			else {
				return ServerSideErrorCommonCudObj;
			}

		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

}
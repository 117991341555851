import AddEstimate from "../components/add/AddEstimate";
import AddProduct from "../components/add/AddProduct";
import AddTransporter from "../components/add/AddTransporter";
import AddUnit from "../components/add/AddUnit";
import ListEstimate from "../components/list/ListEstimate";
import ListProduct from "../components/list/ListProduct";
import ListProductCat from "../components/list/ListProductCat";
import ListTax from "../components/list/ListTax";
import ListTaxCat from "../components/list/ListTaxCat";
import ListTransporter from "../components/list/ListTransporter";
import ListUnit from "../components/list/ListUnit";
import { KbShortcutEnum } from "../enums/KbShortcutEnum";
import { MenuEnum } from "../enums/MenuEnum";

export default class MenuHelper {

	constructor(private openLayout: (element: JSX.Element, title: string) => void) {
	}

	openCommncBook() {
	}

	openListReminder() {
	}

	openAddReminder(id?: number) {
	}

	openListCommnc() {
	}

	openAddCommnc(id?: number) {
	}

	openListLead() {
	}

	openAddLead(id?: number) {
	}

	openAcctBook() {
	}

	openDayBook() {
	}

	//
	openListCdpJournalVou() {
	}

	openAddCdpJournalVou(id?: number) {

	}

	//
	openListJournal() {
	}

	openAddJournal(id?: number) {
	}

	//
	openListContra() {
	}

	openAddContra(id?: number) {
	}

	//
	openListReceipt() {
	}

	openAddReceipt(id?: number) {
	}

	//
	openListPayment() {
	}

	openAddPayment(id?: number) {
	}

	//
	openListSalesReturn() {
	}

	openAddSalesReturn(id?: number) {
	}

	//
	openListSales() {
	}

	openAddSales(id?: number) {
	}

	openAddSalesByEstimateId(estimateId: number) {
	}

	openAddSalesBySalesOrderId(salesOrderId: number) {
	}

	//
	openListPi() {
	}

	openAddPiFromEstimateId(id?: number) {
	}

	openAddPi(id?: number) {
	}

	//
	openListSalesOrder() {
	}

	openAddSalesOrder(id?: number) {
	}

	openAddSalesOrderByEstimateId(estimateId: number) {
	}

	openAddSalesOrderByPiId(piId: number) {
	}

	//
	openListEstimate() {
		this.openLayout(<ListEstimate />, MenuEnum.ESTIMATE.displayName);
	}

	openAddEstimate(id?: number) {
		this.openLayout(<AddEstimate id={id} />, MenuEnum.ESTIMATE.displayName);
	}

	//
	openListPurchaseReturn() {
	}

	openAddPurchaseReturn(id?: number) {
	}

	//
	openListPurchase() {
	}

	openAddPurchase(id?: number) {
	}

	//
	openListPurchaseOrder() {
	}

	openAddPurchaseOrder(id?: number) {
	}

	//
	openListCommVou() {
	}

	openAddCommVou(id?: number) {
	}

	//
	openListOrderAdjust() {
	}

	openAddOrderAdjust(id?: number) {
	}

	//
	openListBank() {
	}

	openAddBank(id?: number) {
	}

	//
	openListCustomer() {
	}

	openAddCustomer(id?: number) {
	}

	//
	openListSupplier() {
	}

	openAddSupplier(id?: number) {
	}

	//
	openListAgent() {
	}

	openAddAgent(id?: number) {
	}

	//
	openListAcct() {
	}

	openAddAcct(id?: number) {
	}

	//
	openListAcctType() {
	}

	openAddAcctType(id?: number) {
	}

	//
	openListInventoryAdjust() {
	}

	openAddInventoryAdjust(id?: number) {
	}

	//
	openListCommList() {
	}

	openAddCommList(id?: number) {
	}

	//
	openListPurchasePriceList() {
	}

	openAddPurchasePriceList(id?: number) {
	}

	//
	openListSalesPriceList() {
	}

	openAddSalesPriceList(id?: number) {
	}

	//
	openListService() {
	}

	openAddService(id?: number) {
	}

	//
	openListProduct() {
		this.openLayout(<ListProduct />, MenuEnum.PROD.displayName);
	}

	openAddProduct(id?: number) {
		this.openLayout(<AddProduct id={id} />, MenuEnum.PROD.displayName);
	}

	//
	openListProductOthCh() {
	}

	openAddProductOthCh(id?: number) {
	}

	//
	openListTaxCat() {
		this.openLayout(<ListTaxCat />, MenuEnum.TAX_CAT.displayName);
	}

	openAddTaxCat(id?: number) {
	}

	//
	openListProductCat() {
		this.openLayout(<ListProductCat />, MenuEnum.CAT.displayName);
	}

	openAddProductCat(id?: number) {
	}

	//
	openListUnit() {
		this.openLayout(<ListUnit />, MenuEnum.UNIT.displayName);
	}

	openAddUnit(id?: number) {
		this.openLayout(<AddUnit />, MenuEnum.UNIT.displayName);
	}

	//
	openListTransporter() {
		this.openLayout(<ListTransporter />, MenuEnum.TRANSPORTER.displayName);
	}

	openAddTransporter(id?: number) {
	}

	//
	openListTax() {
		this.openLayout(<ListTax />, MenuEnum.TAX.displayName);
	}

	openAddTax(id?: number) {
		this.openLayout(<AddTransporter id={id} />, MenuEnum.TRANSPORTER.displayName);
	}

	//
	openCompanyInfo() {
	}

	//
	openListLeadSrc() {
	}

	openAddLeadSrc(id?: number) {
	}

	//
	openListLeadStatus() {
	}

	openAddLeadStatus(id?: number) {
	}

	//
	openListState() {
	}

	openAddState(id?: number) {
	}

	// == 
	//
	openListAccessRole() {
	}

	openAddAccessRole(id?: number) {
	}

	//
	openListUser() {
	}

	openAddUser(id?: number) {
	}

	//
	openListView(menu: MenuEnum) {
		switch (menu) {
			case MenuEnum.REMINDER:
				return this.openListReminder();
			case MenuEnum.COMMNC:
				return this.openListCommnc();
			case MenuEnum.LEAD:
				return this.openListLead();
			case MenuEnum.ACCT_BOOK:
				return this.openAcctBook();
			case MenuEnum.DAY_BOOK:
				return this.openDayBook();
			case MenuEnum.CDP_JOURNAL_VOU:
				return this.openListCdpJournalVou();
			case MenuEnum.JOURNAL:
				return this.openListJournal();
			case MenuEnum.CONTRA:
				return this.openListContra();
			case MenuEnum.RECEIPT:
				return this.openListReceipt();
			case MenuEnum.PAYMENT:
				return this.openListPayment();
			case MenuEnum.SALES_RETURN:
				return this.openListSalesReturn();
			case MenuEnum.SALES:
				return this.openListSales();
			case MenuEnum.PI:
				return this.openListPi();
			case MenuEnum.SALES_ORDER:
				return this.openListSalesOrder();
			case MenuEnum.ESTIMATE:
				return this.openListEstimate();
			case MenuEnum.PURCHASE_RETURN:
				return this.openListPurchaseReturn();
			case MenuEnum.PURCHASE:
				return this.openListPurchase();
			case MenuEnum.PURCHASE_ORDER:
				return this.openListPurchaseOrder();
			case MenuEnum.COMM_VOU:
				return this.openListCommVou();
			case MenuEnum.ORDER_ADJUST:
				return this.openListOrderAdjust();
			case MenuEnum.BANK:
				return this.openListBank();
			case MenuEnum.CUSTOMER:
				return this.openListCustomer();
			case MenuEnum.SUPPLIER:
				return this.openListSupplier();
			case MenuEnum.AGENT:
				return this.openListAgent();
			case MenuEnum.ACCT:
				return this.openListAcct();
			case MenuEnum.ACCT_TYPE:
				return this.openListAcctType();
			case MenuEnum.INV_ADJUST:
				return this.openListInventoryAdjust();
			case MenuEnum.COMM_LIST:
				return this.openListCommList();
			case MenuEnum.PURCHASE_PRICE_LIST:
				return this.openListPurchasePriceList();
			case MenuEnum.SALES_PRICE_LIST:
				return this.openListSalesPriceList();
			case MenuEnum.SERVICE:
				return this.openListService();
			case MenuEnum.PROD:
				return this.openListProduct();
			case MenuEnum.PROD_OTH_CH:
				return this.openListProductOthCh();
			case MenuEnum.TAX_CAT:
				return this.openListTaxCat();
			case MenuEnum.CAT:
				return this.openListProductCat();
			case MenuEnum.UNIT:
				return this.openListUnit();

			case MenuEnum.TRANSPORTER:
				return this.openListTransporter();
			case MenuEnum.TAX:
				return this.openListTax();
			case MenuEnum.COMPANY_INFO:
				return this.openCompanyInfo();
			case MenuEnum.LEAD_SRC:
				return this.openListLeadSrc();
			case MenuEnum.LEAD_STATUS:
				return this.openListLeadStatus();
		}
	};

	//
	openAddView(menu: MenuEnum) {
		switch (menu) {
			case MenuEnum.REMINDER:
				return this.openAddReminder();
			case MenuEnum.COMMNC:
				return this.openAddCommnc();
			case MenuEnum.LEAD:
				return this.openAddLead();
			case MenuEnum.JOURNAL:
				return this.openAddJournal();
			case MenuEnum.CONTRA:
				return this.openAddContra();
			case MenuEnum.RECEIPT:
				return this.openAddReceipt();
			case MenuEnum.PAYMENT:
				return this.openAddPayment();
			case MenuEnum.SALES_RETURN:
				return this.openAddSalesReturn();
			case MenuEnum.SALES:
				return this.openAddSales();
			case MenuEnum.PI:
				return this.openAddPi();
			case MenuEnum.SALES_ORDER:
				return this.openAddSalesOrder();
			case MenuEnum.ESTIMATE:
				return this.openAddEstimate();
			case MenuEnum.PURCHASE_RETURN:
				return this.openAddPurchaseReturn();
			case MenuEnum.PURCHASE:
				return this.openAddPurchase();
			case MenuEnum.PURCHASE_ORDER:
				return this.openAddPurchaseOrder();
			case MenuEnum.COMM_VOU:
				return this.openAddCommVou();
			case MenuEnum.ORDER_ADJUST:
				return this.openAddOrderAdjust();
			case MenuEnum.BANK:
				return this.openAddBank();
			case MenuEnum.CUSTOMER:
				return this.openAddCustomer();
			case MenuEnum.SUPPLIER:
				return this.openAddSupplier();
			case MenuEnum.AGENT:
				return this.openAddAgent();
			case MenuEnum.ACCT:
				return this.openAddAcct();
			case MenuEnum.ACCT_TYPE:
				return this.openAddAcctType();
			case MenuEnum.INV_ADJUST:
				return this.openAddInventoryAdjust();
			case MenuEnum.SERVICE:
				return this.openAddService();
			case MenuEnum.PROD:
				return this.openAddProduct();
			case MenuEnum.PROD_OTH_CH:
				return this.openAddProductOthCh();
			case MenuEnum.TAX_CAT:
				return this.openAddTaxCat();
			case MenuEnum.CAT:
				return this.openAddProductCat();
			case MenuEnum.UNIT:
				return this.openAddUnit();
			case MenuEnum.TRANSPORTER:
				return this.openAddTransporter();
			case MenuEnum.TAX:
				return this.openAddTax();
			case MenuEnum.LEAD_SRC:
				return this.openAddLeadSrc();
			case MenuEnum.LEAD_STATUS:
				return this.openAddLeadStatus();
		}
	};

	//
	openEditVoucher(id: number) {

	};

	//
	handleKbsc(kbShortcutEnum: KbShortcutEnum) {

		switch (kbShortcutEnum) {

			case KbShortcutEnum.COMMNC_BOOK: {
				return this.openCommncBook();
			}
			case KbShortcutEnum.REMINDER: {
				return this.openListReminder();
			}
			case KbShortcutEnum.REMINDER_ADD: {
				return this.openAddReminder();
			}
			case KbShortcutEnum.COMMNC: {
				return this.openListCommnc();
			}
			case KbShortcutEnum.COMMNC_ADD: {
				return this.openAddCommnc();
			}
			case KbShortcutEnum.LEAD: {
				return this.openListLead();
			}
			case KbShortcutEnum.LEAD_ADD: {
				return this.openAddLead();
			}
			case KbShortcutEnum.ACCT_BOOK: {
				return this.openAcctBook();
			}
			case KbShortcutEnum.DAY_BOOK: {
				return this.openDayBook();
			}
			case KbShortcutEnum.CDP: {
				return this.openListCdpJournalVou();
			}
			case KbShortcutEnum.CDP_ADD: {
				return this.openAddCdpJournalVou();
			}
			case KbShortcutEnum.JOURNAL: {
				return this.openListJournal();
			}
			case KbShortcutEnum.JOURNAL_ADD: {
				return this.openAddJournal();
			}
			case KbShortcutEnum.CONTRA: {
				return this.openListContra();
			}
			case KbShortcutEnum.CONTRA_ADD: {
				return this.openAddContra();
			}
			case KbShortcutEnum.RECEIPT: {
				return this.openListReceipt();
			}
			case KbShortcutEnum.RECEIPT_ADD: {
				return this.openAddReceipt();
			}
			case KbShortcutEnum.PAYMENT: {
				return this.openListPayment();
			}
			case KbShortcutEnum.PAYMENT_ADD: {
				return this.openAddPayment();
			}
			case KbShortcutEnum.SALES_RETURN: {
				return this.openListSalesReturn();
			}
			case KbShortcutEnum.SALES_RETURN_ADD: {
				return this.openAddSalesReturn();
			}
			case KbShortcutEnum.SALES: {
				return this.openListSales();
			}
			case KbShortcutEnum.SALES_ADD: {
				return this.openAddSales();
			}
			case KbShortcutEnum.PI: {
				return this.openListPi();
			}
			case KbShortcutEnum.PI_ADD: {
				return this.openAddPi();
			}
			case KbShortcutEnum.SALES_ORDER: {
				return this.openListSalesOrder();
			}
			case KbShortcutEnum.SALES_ORDER_ADD: {
				return this.openAddSalesOrder();
			}
			case KbShortcutEnum.ESTIMATE: {
				return this.openListEstimate();
			}
			case KbShortcutEnum.ESTIMATE_ADD: {
				return this.openAddEstimate();
			}
			case KbShortcutEnum.PURCHASE_RETURN: {
				return this.openListPurchaseReturn();
			}
			case KbShortcutEnum.PURCHASE_RETURN_ADD: {
				return this.openAddPurchaseOrder();
			}
			case KbShortcutEnum.PURCHASE: {
				return this.openListPurchase();
			}
			case KbShortcutEnum.PURCHASE_ADD: {
				return this.openAddPurchase();
			}
			case KbShortcutEnum.PURCHASE_ORDER: {
				return this.openListPurchaseOrder();
			}
			case KbShortcutEnum.PURCHASE_ORDER_ADD: {
				return this.openAddPurchaseOrder();
			}
			case KbShortcutEnum.COMM_VOU: {
				return this.openListCommVou();
			}
			case KbShortcutEnum.COMM_VOU_ADD: {
				return this.openAddCommVou();
			}
			case KbShortcutEnum.CASH_DISC_VOU: {
				break;
			}
			case KbShortcutEnum.CASH_DISC_VOU_ADD: {
				break;
			}
			case KbShortcutEnum.BANK: {
				return this.openListBank();
			}
			case KbShortcutEnum.BANK_ADD: {
				return this.openAddBank();
			}
			case KbShortcutEnum.CUSTOMER: {
				return this.openListCustomer();
			}
			case KbShortcutEnum.CUSTOMER_ADD: {
				return this.openAddCustomer();
			}
			case KbShortcutEnum.SUPPLIER: {
				return this.openListSupplier();
			}
			case KbShortcutEnum.SUPPLIER_ADD: {
				return this.openAddSupplier();
			}
			case KbShortcutEnum.AGENT: {
				return this.openListAgent();
			}
			case KbShortcutEnum.AGENT_ADD: {
				return this.openAddAgent();
			}
			case KbShortcutEnum.ACCT: {
				return this.openListAcct();
			}
			case KbShortcutEnum.ACCT_ADD: {
				return this.openAddAcct();
			}
			case KbShortcutEnum.ACCT_TYPE: {
				return this.openListAcctType();
			}
			case KbShortcutEnum.ACCT_TYPE_ADD: {
				return this.openAddAcctType();
			}
			case KbShortcutEnum.INV_ADJUST: {
				return this.openListInventoryAdjust();
			}
			case KbShortcutEnum.INV_ADJUST_ADD: {
				return this.openAddInventoryAdjust();
			}
			case KbShortcutEnum.COMM_LIST: {
				return this.openListCommList();
			}
			case KbShortcutEnum.COMM_LIST_ADD: {
				return this.openAddCommList();
			}
			case KbShortcutEnum.SALES_PRICE_LIST: {
				return this.openListSalesPriceList();
			}
			case KbShortcutEnum.SALES_PRICE_LIST_ADD: {
				return this.openAddSalesPriceList();
			}
			case KbShortcutEnum.SERVICE: {
				return this.openListService();
			}
			case KbShortcutEnum.SERVICE_ADD: {
				return this.openAddService();
			}
			case KbShortcutEnum.PRODUCT: {
				return this.openListProduct();
			}
			case KbShortcutEnum.PRODUCT_ADD: {
				return this.openAddProduct();
			}
			case KbShortcutEnum.PROD_OTH_CHARGE: {
				return this.openListProductOthCh();
			}
			case KbShortcutEnum.PROD_OTH_CHARGE_ADD: {
				return this.openAddProductOthCh();
			}
			case KbShortcutEnum.TAX_CAT: {
				return this.openListTaxCat();
			}
			case KbShortcutEnum.TAX_CAT_ADD: {
				return this.openAddTaxCat();
			}
			case KbShortcutEnum.PROD_CAT: {
				return this.openListProductCat();
			}
			case KbShortcutEnum.PROD_CAT_ADD: {
				return this.openAddProductCat();
			}
			case KbShortcutEnum.UNIT: {
				return this.openListUnit();
			}
			case KbShortcutEnum.UNIT_ADD: {
				return this.openAddUnit();
			}

			case KbShortcutEnum.TRANSPORTER: {
				return this.openListTransporter();
			}
			case KbShortcutEnum.TRANSPORTER_ADD: {
				return this.openAddTransporter();
			}
			case KbShortcutEnum.TAX: {
				return this.openListTax();
			}
			case KbShortcutEnum.TAX_ADD: {
				return this.openAddTax();
			}
			case KbShortcutEnum.MENU_CONFIG: {
				break;
			}

		}

	};
}
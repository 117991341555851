import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import TransporterReqType from "../types/req/TransporterReqType";
import ErrorResType from "../types/res/ErrorResType";
import TransporterResType from "../types/res/TransporterResType";
import TransporterListResType from "../types/res/list/TransporterListResType";

export default class TransporterApi {
	static async getTransporters(): Promise<CommonGetListType<TransporterListResType>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.TRANSPORTER.name), requestOptions);

			if (response.status === 200) {
				const data: TransporterListResType[] = await response.json();
				return {
					isSuccess: true,
					items: data
				};
			}
			else if (response.status === 400) {
				const data: ErrorResType = await response.json();
				return {
					isSuccess: false,
					errorRes: data
				};
			}
			else {
				return ServerSideErrorCommonCudObj;
			}
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async createTransporter(transporter: TransporterReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(transporter)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.TRANSPORTER.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateTransporter(id: number, transporter: TransporterReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(transporter)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.TRANSPORTER.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getTransporter(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.TRANSPORTER.name, id), requestOptions);
		const data: TransporterResType = await res.json();
		return data;

	}
}

import DropDownItemType from "../types/DropDownItemType";
import PaymentStatusListResType from "../types/res/list/PaymentStatusListResType";

export default class PaymentStatusHelper {

	static getPaymentStatusListResArrForDd(listPaymentStatuss: PaymentStatusListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listPaymentStatuss.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getPaymentStatusListResForDd(paymentStatus: PaymentStatusListResType): DropDownItemType {
		return {
			id: paymentStatus.id,
			name: paymentStatus.name
		};
	}

	static getPaymentStatusListResMap(itemArr: PaymentStatusListResType[]) {
		let map = new Map<number, PaymentStatusListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import { GetProdObjectTypeEnum } from "../enums/GetProdObjectTypeEnum";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import LabelReqDto from "../types/req/LabelReqDto";
import ProductReqHoDto from "../types/req/ProductReqHoDto";
import ErrorResType from "../types/res/ErrorResType";
import ProductResHoDto from "../types/res/ProductResHoDto";
import ProdForEsimateListResType from "../types/res/list/ProdForEsimateListResType";
import ProductListResType from "../types/res/list/ProductListResType";

export default class ProductApi {

	static async getProducts(): Promise<CommonGetListType<ProductListResType>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name), requestOptions);

			if (response.status === 200) {
				const data: ProductListResType[] = await response.json();
				return {
					isSuccess: true,
					items: data
				};
			}
			else if (response.status === 400) {
				const data: ErrorResType = await response.json();
				return {
					isSuccess: false,
					errorRes: data
				};
			}
			else {
				return ServerSideErrorCommonCudObj;
			}

		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getProdForEstimate(): Promise<CommonGetListType<ProdForEsimateListResType>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name)
				+ getQueryString({ returnObjectType: GetProdObjectTypeEnum.PROD_FOR_ESTIMATE.enumName.toUpperCase() }),
				requestOptions);

			if (response.status === 200) {
				const data: ProdForEsimateListResType[] = await response.json();
				return {
					isSuccess: true,
					items: data
				};
			}
			else if (response.status === 400) {
				const data: ErrorResType = await response.json();
				return {
					isSuccess: false,
					errorRes: data
				};
			}
			else {
				return ServerSideErrorCommonCudObj;
			}

		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async createProduct(product: ProductReqHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(product)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateProduct(id: number, product: ProductReqHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(product)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PRODUCT.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getProduct(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PRODUCT.name, id), requestOptions);
		const data: ProductResHoDto = await res.json();
		return data;

	}

	static async print(labelReqDto: LabelReqDto) {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(labelReqDto)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, "labels"), requestOptions);

		var blob = await res.blob();
		return new Blob([blob], { type: 'application/pdf' });

	}
}

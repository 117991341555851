import MenuResType from "../types/res/MenuResType";
import MenuButtonOnly from "./MenuButtonOnly";
import MenuButtonWiArrow from "./MenuButtonWiArrow";
import MenuButtonWiPlus from "./MenuButtonWiPlus";

interface MenuButtonProps {
	menu: MenuResType;
	index: number;
	openMenuView: (parentMenu: MenuResType) => void;
}

function MenuButton({
	menu,
	index,
	openMenuView
}: MenuButtonProps): JSX.Element {
	if (menu.isSystemMenu) {
		if (menu.hasAdd) {
			return (
				<MenuButtonWiPlus
					index={index}
					menu={menu}
				/>
			);
		} else {
			return (
				<MenuButtonOnly index={index} menu={menu} />
			);
		}
	}
	return (
		<MenuButtonWiArrow index={index} menu={menu} openMenuView={openMenuView} />
	);
}

export default MenuButton;

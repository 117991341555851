import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import MessageDialog from "../../dialogs/MessageDialog";
import { YesNoEnum } from "../../enums/YesNoEnum";
import StringHelper2 from "../../helpers/StringHelper2";
import YesNoHelper from "../../helpers/YesNoHelper";
import DropDownItemType from "../../types/DropDownItemType";
import OpeningStockType from "../../types/OpeningStockType";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddOpeningStockFormSvProps {
	handleItemChange: (addOpeningStock: OpeningStockType) => void;
	openingStockToEdit: OpeningStockType | null;
	handleClickCancelEdit: () => void;
	id?: number
}

function AddOpeningStockFormSv({
	handleItemChange,
	openingStockToEdit,
	handleClickCancelEdit,
	id
}: AddOpeningStockFormSvProps): JSX.Element {

	const [qtyStr, setQtyStr] = useState<string>("");
	const [rateStr, setRateStr] = useState<string>("");
	const [amtStr, setAmtStr] = useState<string>("");
	const [rawPriceStr, setRawPriceStr] = useState<string>("");
	const [polishPriceStr, setPolishPriceStr] = useState<string>("");
	const [totalCostPriceStr, setTotalCostPriceStr] = useState<string>("");
	const [additionalCostStr, setAdditionalCostStr] = useState<string>("");
	const [minSalesPriceStr, setMinSalesPriceStr] = useState<string>("");
	const [wholesaleSalesPriceStr, setWholesaleSalesPriceStr] = useState<string>("");
	const [internetSalesPriceStr, setInternetSalesPriceStr] = useState<string>("");
	const [agentSalesPriceStr, setAgentSalesPriceStr] = useState<string>("");
	const [mrpStr, setMrpStr] = useState<string>("");
	const [maxSalesPriceStr, setMaxSalesPriceStr] = useState<string>("");
	const [weightStr, setWeightStr] = useState<string>("");
	const [isRatePerWeight, setIsRatePerWeight] = useState<boolean>(false);
	const [ratePerWeightStr, setRatePerWeightStr] = useState<string>("");
	const [purchaseDate, setPurchaseDate] = useState<Date | null>(new Date());
	//
	const [yesNosForDd, setYesNosForDd] = useState<DropDownItemType[]>([]);
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {
			setYesNosForDd(YesNoHelper.getYesNosForDd());
		}
		process();
	}, []);

	useEffect(() => {
		if (openingStockToEdit != null) {
			setQtyStr(openingStockToEdit.qty.toString());
			setRateStr(openingStockToEdit.rate.toString());
			setAmtStr(openingStockToEdit.derivedAmt?.toString() ?? "");
			setRawPriceStr(openingStockToEdit.rawPrice?.toString() ?? "");
			setPolishPriceStr(openingStockToEdit.polishPrice?.toString() ?? "");
			setTotalCostPriceStr(openingStockToEdit.totalCostPrice?.toString() ?? "");
			setAdditionalCostStr(openingStockToEdit.additionalCost?.toString() ?? "");
			setMinSalesPriceStr(openingStockToEdit.minSalesPrice?.toString() ?? "");
			setWholesaleSalesPriceStr(openingStockToEdit.wholesaleSalesPrice?.toString() ?? "");
			setInternetSalesPriceStr(openingStockToEdit.internetSalesPrice?.toString() ?? "");
			setAgentSalesPriceStr(openingStockToEdit.agentSalesPrice?.toString() ?? "");
			setMrpStr(openingStockToEdit.mrp?.toString() ?? "");
			setMaxSalesPriceStr(openingStockToEdit.maxSalesPrice?.toString() ?? "");
			setWeightStr(openingStockToEdit.weight?.toString() ?? "");
			setIsRatePerWeight(openingStockToEdit.isRatePerWeight ?? false);
			setPurchaseDate(openingStockToEdit.purchaseDate);
			setRatePerWeightStr(openingStockToEdit.ratePerWeight?.toString() ?? "");

		}
	}, [openingStockToEdit]);

	const _handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

	}

	const fillInUi = (/*productResType: EstimateResType*/) => {

	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setQtyStr("");
		setRateStr("");
		setAmtStr("");
		setRawPriceStr("");
		setPolishPriceStr("");
		setTotalCostPriceStr("");
		setAdditionalCostStr("");
		setMinSalesPriceStr("");
		setWholesaleSalesPriceStr("");
		setInternetSalesPriceStr("");
		setAgentSalesPriceStr("");
		setMrpStr("");
		setMaxSalesPriceStr("");
		setWeightStr("");
		setIsRatePerWeight(false);
		setRatePerWeightStr("");
		setPurchaseDate(null);
	};

	const handleSave = (e: React.MouseEvent) => {

		e.preventDefault();

		if (!isValid()) {
			return;
		}

		let qtyLoc = parseFloat(qtyStr);
		let rateLoc = parseFloat(rateStr);
		let amtLoc = parseFloat(amtStr);
		let rawPriceLoc = parseFloat(rawPriceStr);
		let polishPriceLoc = parseFloat(polishPriceStr);
		let totalCostPriceLoc = parseFloat(totalCostPriceStr);
		let additionalCostLoc = parseFloat(additionalCostStr);
		let minSalesPriceLoc = parseFloat(minSalesPriceStr);
		let wholesaleSalesPriceLoc = parseFloat(wholesaleSalesPriceStr);
		let internetSalesPriceLoc = parseFloat(internetSalesPriceStr);
		let agentSalesPriceLoc = parseFloat(agentSalesPriceStr);
		let mrpLoc = parseFloat(mrpStr);
		let maxSalesPriceLoc = parseFloat(maxSalesPriceStr);
		let weightLoc = parseFloat(weightStr);
		let isRatePerWeightLoc = isRatePerWeight;
		let ratePerWeightLoc = parseFloat(ratePerWeightStr);
		let purchaseDateLoc = purchaseDate;

		let addOpeningStockLoc: OpeningStockType = {
			qty: qtyLoc,
			rate: rateLoc,
			derivedAmt: amtLoc,
			rawPrice: rawPriceLoc,
			polishPrice: polishPriceLoc,
			totalCostPrice: totalCostPriceLoc,
			additionalCost: additionalCostLoc,
			minSalesPrice: minSalesPriceLoc,
			wholesaleSalesPrice: wholesaleSalesPriceLoc,
			internetSalesPrice: internetSalesPriceLoc,
			agentSalesPrice: agentSalesPriceLoc,
			mrp: mrpLoc,
			maxSalesPrice: maxSalesPriceLoc,
			weight: weightLoc,
			isRatePerWeight: isRatePerWeightLoc,
			ratePerWeight: ratePerWeightLoc,
			purchaseDate: purchaseDateLoc
		};

		handleItemChange(addOpeningStockLoc);

		clearBoxes();

	};

	const handleCancel = async (e: React.MouseEvent) => {
		e.preventDefault();
		clearBoxes();
		handleClickCancelEdit();
	}

	const isValid = (): boolean => {

		if (qtyStr === undefined
			|| qtyStr === null
			|| !StringHelper2.isNum(qtyStr)
		) {
			setMessageDialogMessage("Invalid quantity");
			setShowMessageDialog(true);
			return false;
		}

		if (
			rateStr === undefined
			|| rateStr === null
			|| !StringHelper2.isNum(rateStr)) {
			setMessageDialogMessage("Invalid rate");
			setShowMessageDialog(true);
			return false;
		}

		if (
			rawPriceStr === undefined
			|| rawPriceStr === null
			|| !StringHelper2.isNum(rawPriceStr)) {
			setMessageDialogMessage("Invalid raw price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			polishPriceStr === undefined
			|| polishPriceStr === null
			|| !StringHelper2.isNum(polishPriceStr)) {
			setMessageDialogMessage("Invalid polish price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			totalCostPriceStr === undefined
			|| totalCostPriceStr === null
			|| !StringHelper2.isNum(totalCostPriceStr)) {
			setMessageDialogMessage("Invalid total cost price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			additionalCostStr === undefined
			|| additionalCostStr === null
			|| !StringHelper2.isNum(additionalCostStr)) {
			setMessageDialogMessage("Invalid additional cost");
			setShowMessageDialog(true);
			return false;
		}

		if (
			minSalesPriceStr === undefined
			|| minSalesPriceStr === null
			|| !StringHelper2.isNum(minSalesPriceStr)) {

			setMessageDialogMessage("Invalid minimum sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			wholesaleSalesPriceStr === undefined
			|| wholesaleSalesPriceStr === null
			|| !StringHelper2.isNum(wholesaleSalesPriceStr)) {
			setMessageDialogMessage("Invalid wholesale sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			internetSalesPriceStr === undefined
			|| internetSalesPriceStr === null
			|| !StringHelper2.isNum(internetSalesPriceStr)) {
			setMessageDialogMessage("Invalid internet sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			agentSalesPriceStr === undefined
			|| agentSalesPriceStr === null
			|| !StringHelper2.isNum(agentSalesPriceStr)) {
			setMessageDialogMessage("Invalid agent sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			mrpStr === undefined
			|| mrpStr === null
			|| !StringHelper2.isNum(mrpStr)) {

			setMessageDialogMessage("Invalid MRP");
			setShowMessageDialog(true);
			return false;
		}

		if (
			maxSalesPriceStr === undefined
			|| maxSalesPriceStr === null
			|| !StringHelper2.isNum(maxSalesPriceStr)) {
			setMessageDialogMessage("Invalid maximum sales price");
			setShowMessageDialog(true);
			return false;
		}

		if (
			weightStr === undefined
			|| weightStr === null
			|| !StringHelper2.isNum(weightStr)) {
			setMessageDialogMessage("Invalid weight");
			setShowMessageDialog(true);
			return false;
		}

		if (
			ratePerWeightStr === undefined
			|| ratePerWeightStr === null
			|| !StringHelper2.isNum(ratePerWeightStr)) {
			setMessageDialogMessage("Invalid rate per weight");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>

			<Row className="mb-3" xs="auto">

				<Form.Group as={Col}>
					<Form.Label>Quantity</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={qtyStr}
						onChange={(e) => setQtyStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Rate</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={rateStr}
						onChange={(e) => setRateStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Amount</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={amtStr}
						onChange={(e) => setAmtStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Raw Price</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={rawPriceStr}
						onChange={(e) => setRawPriceStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Polish Price</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={polishPriceStr}
						onChange={(e) => setPolishPriceStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Total Cost Price</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={totalCostPriceStr}
						onChange={(e) => setTotalCostPriceStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Additional Cost</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={additionalCostStr}
						onChange={(e) => setAdditionalCostStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Minimum Sales Price</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={minSalesPriceStr}
						onChange={(e) => setMinSalesPriceStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Wholesale Sales Price</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={wholesaleSalesPriceStr}
						onChange={(e) => setWholesaleSalesPriceStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Internet Sales Price</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={internetSalesPriceStr}
						onChange={(e) => setInternetSalesPriceStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Agent Sales Price</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={agentSalesPriceStr}
						onChange={(e) => setAgentSalesPriceStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>MRP</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={mrpStr}
						onChange={(e) => setMrpStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Maximum Sales Price</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={maxSalesPriceStr}
						onChange={(e) => setMaxSalesPriceStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Weight</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={weightStr}
						onChange={(e) => setWeightStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Is Rate Per Weight?</Form.Label>
					<DownshiftSelect
						inputId={"isRatePerWeight"}
						value={isRatePerWeight ? YesNoHelper.getYesNoForDd(YesNoEnum.getByVal(isRatePerWeight)!) : null}
						onChange={(e) => setIsRatePerWeight(e == null ? false : YesNoEnum.getById(e)?.val!)}
						options={yesNosForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Rate Per Weight</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={ratePerWeightStr}
						onChange={(e) => setRatePerWeightStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Rate Per Weight</Form.Label>
					<ReactDatePicker
						id="dpDate"
						dateFormat="dd-MM-yyyy"
						selected={purchaseDate}
						onChange={(e) => setPurchaseDate(e)}
						className="form-control form-control-sm"
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						onClick={(e) => handleSave(e)}
						// onClick={(e) => console.log("")}
						as={Button}>Save</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}
						onClick={handleCancel}
					>Cancel</FormControl>
				</Form.Group>

			</Row>

			<MessageDialog
				modalMessage={messageDialogMessage}
				showDialog={showMessageDialog}
				setShowDialog={setShowMessageDialog}
			/>

		</>
	);
}

export default AddOpeningStockFormSv;

import { useEffect, useState } from "react";
import TransporterApi from "../../api/TransporterApi";
import useConfirm from "../../dialogs/ConfirmDialog";
import RowType from "../../types/RowType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import { useGvContext } from "../../GvContext";

function ListTransporter(): JSX.Element {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setselectedId] = useState<number | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	const colNames = [
		"Name",
		"Transporter Id"
	];

	useEffect(() => {
		async function process() {

			let res = await TransporterApi.getTransporters();

			if (res.isSuccess) {

				let dataToReturn: RowType[] = [];

				for (let i = 0; i < res.items?.length!; i++) {

					let row = res.items![i];

					let oneRowArray = [];
					oneRowArray.push(row.name);
					oneRowArray.push(row.transporterId);
					dataToReturn.push({
						id: row.id,
						data: oneRowArray
					});
				};

				setRows(dataToReturn);
			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}
		}
		process();
	}, []);

	const handleClickAdd = () => {
		gv.menuHelper.openAddTransporter()
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
	};

	const handleClickDelete = async () => {
		console.log(selectedId);
		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		console.log(choice);
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const handleCloseDeleteDialog = () => {

	};

	const handleCloseMessageDialog = () => {
		setShowMessageDialog(false);
	};

	const doDelete = () => {
		console.log("deleted");
	};

	return (
		<>
			<ListViewButtonBar
				
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setselectedId}
				/>
			) : null}


		</>
	);
}

export default ListTransporter;

import { useEffect, useState } from "react";
import ProductApi from "../../api/ProductApi";
import RowType from "../../types/RowType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import { useGvContext } from "../../GvContext";
import LabelQtyReqDto from "../../types/req/LabelQtyReqDto";

function ListProduct(): JSX.Element {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const gv = useGvContext();

	const colNames = [
		"Name",
		"Code",
		"Description",
		"Unit",
		"HSN",
		"Raw Price",
		"Polish Price",
		"Total Cost Price",
		"Additional Cost",
		"Min. Sales Price",
		"Wholesale Sales Price",
		"Internet Sales Price",
		"Agent Sales Price",
		"MRP",
		"Max. Sales Price",
		"Discount",
		"Product Category",
		"Tax Category",
		"Tax",
		"Note",
		"Is Sold?",
		"Is Made?",
		"Min. Quantity",
		"Reorder Quantity",
		"Supplier (Raw)",
		"Polished By Supplier",
		"Weight",
		"Is Rate per Weight",
		"Rate per Weight",
		"Created By",
		"Created At"
	];

	useEffect(() => {
		async function process() {

			const res = await ProductApi.getProducts();

			if (res.isSuccess) {

				let dataToReturn: RowType[] = [];
				for (let i = 0; i < res.items?.length!; i++) {

					let row = res.items![i];

					let oneRowArray = [];
					oneRowArray.push(row.name);
					oneRowArray.push(row.code === null ? "" : row.code);
					oneRowArray.push(row.desc === null ? "" : row.desc);
					oneRowArray.push(row.unitName === null ? "" : row.unitName);
					oneRowArray.push(row.hsn === null ? "" : row.hsn.toString());
					oneRowArray.push(row.rawPrice === null ? "" : row.rawPrice.toString());
					oneRowArray.push(row.polishPrice === null ? "" : row.polishPrice.toString());
					oneRowArray.push(row.totalCostPrice === null ? "" : row.totalCostPrice.toString());
					oneRowArray.push(row.additionalCost === null ? "" : row.additionalCost.toString());
					oneRowArray.push(row.minSalesPrice === null ? "" : row.minSalesPrice.toString());
					oneRowArray.push(row.wholesaleSalesPrice === null ? "" : row.wholesaleSalesPrice.toString());
					oneRowArray.push(row.internetSalesPrice === null ? "" : row.internetSalesPrice.toString());
					oneRowArray.push(row.agentSalesPrice === null ? "" : row.agentSalesPrice.toString());
					oneRowArray.push(row.mrp === null ? "" : row.mrp.toString());
					oneRowArray.push(row.maxSalesPrice === null ? "" : row.maxSalesPrice.toString());
					oneRowArray.push(row.discount === null ? "" : row.discount.toString());
					oneRowArray.push(row.productCategoryName === null ? "" : row.productCategoryName);
					oneRowArray.push(row.taxCategoryName === null ? "" : row.taxCategoryName);
					oneRowArray.push(row.taxName === null ? "" : row.taxName);
					oneRowArray.push(row.note === null ? "" : row.note);
					oneRowArray.push(row.isSold ? "YES" : "NO");
					oneRowArray.push(row.isMade ? "YES" : "NO");
					oneRowArray.push(row.minQty === null ? "" : row.minQty.toString());
					oneRowArray.push(row.reorderQty === null ? "" : row.reorderQty.toString());
					oneRowArray.push(row.rawSupplierName === null ? "" : row.rawSupplierName);
					oneRowArray.push(row.polishedBySupplierName === null ? "" : row.polishedBySupplierName);
					oneRowArray.push(row.weight === null ? "" : row.weight.toString());
					oneRowArray.push(row.isRatePerWeight ? "YES" : "NO");
					oneRowArray.push(row.ratePerWeight === null ? "" : row.ratePerWeight.toString());
					oneRowArray.push(row.createdByUserName === null ? "" : row.createdByUserName);
					oneRowArray.push(row.createdAt === null ? "" : row.createdAt.toString());

					dataToReturn.push({
						id: row.id,
						data: oneRowArray
					});
				};

				setRows(dataToReturn);
			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}

		}
		process();
	}, []);

	const handleClickAdd = () => {
		gv.menuHelper.openAddProduct();
	};

	const handleClickEdit = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddProduct(selectedId);

	};

	const handleClickDelete = () => {
		console.log("handleClickDelete");
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = async () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let productResHoDto = await ProductApi.getProduct(selectedId);

		let openingStockLabelQtyReqDto: LabelQtyReqDto[] = [];

		for (let i = 0; i < productResHoDto?.openingStockResDtos?.length!; i++) {

			let openingStockResDto = productResHoDto.openingStockResDtos![i];

			openingStockLabelQtyReqDto.push({
				id: openingStockResDto.id,
				qty: openingStockResDto.qty
			});

		}

		const fileURL = window.URL.createObjectURL(await ProductApi.print({labelOpeningStockReqDtos: openingStockLabelQtyReqDto}));
		// const a = document.createElement('a');
		// a.href = fileURL;
		// a.download = "download";
		// a.target = '_blank';
		// a.click();
		// window.URL.revokeObjectURL(fileURL);	
		//Open the URL on new Window
		const pdfWindow = window.open();
		pdfWindow!.location.href = fileURL;

	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	return (
		<>
			<ListViewButtonBar
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>
			) : null}
		</>
	);
}

export default ListProduct;

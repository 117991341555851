import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import { GetProdObjectTypeEnum } from "../enums/GetProdObjectTypeEnum";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import UnitReqType from "../types/req/UnitReqType";
import ErrorResType from "../types/res/ErrorResType";
import UnitResType from "../types/res/UnitResType";
import ProdForEsimateListResType from "../types/res/list/ProdForEsimateListResType";
import UnitListResType from "../types/res/list/UnitListResType";

export default class UnitApi {

	static async getUnits(): Promise<CommonGetListType<UnitListResType>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.UNIT.name), requestOptions);

			if (response.status === 200) {
				const data: UnitListResType[] = await response.json();
				return {
					isSuccess: true,
					items: data
				};
			}
			else if (response.status === 400) {
				const data: ErrorResType = await response.json();
				return {
					isSuccess: false,
					errorRes: data
				};
			}
			else {
				return ServerSideErrorCommonCudObj;
			}
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getProdForEstimate() {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.UNIT.name)
			+ getQueryString({ returnObjectType: GetProdObjectTypeEnum.PROD_FOR_ESTIMATE.enumName.toUpperCase() }),
			requestOptions);
		const listUnit: ProdForEsimateListResType[] = await res.json();
		return listUnit

	}

	static async createUnit(unit: UnitReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(unit)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.UNIT.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateUnit(id: number, unit: UnitReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(unit)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.UNIT.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getUnit(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.UNIT.name, id), requestOptions);
		const data: UnitResType = await res.json();
		return data;

	}
}

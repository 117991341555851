import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import CountryApi from "../../api/CountryApi";
import EstimateApi from "../../api/EstimateApi";
import EstimateStatusApi from "../../api/EstimateStatusApi";
import PaymentModeApi from "../../api/PaymentModeApi";
import PaymentStatusApi from "../../api/PaymentStatusApi";
import PaymentTermApi from "../../api/PaymentTermApi";
import ProductApi from "../../api/ProductApi";
import SalesChannelApi from "../../api/SalesChannelApi";
import StateApi from "../../api/StateApi";
import TaxApi from "../../api/TaxApi";
import TaxCatApi from "../../api/TaxCatApi";
import TncApi from "../../api/TncApi";
import TradeTermApi from "../../api/TradeTermApi";
import TransportModeApi from "../../api/TransportModeApi";
import TransporterApi from "../../api/TransporterApi";
import UnitApi from "../../api/UnitApi";
import VouOthChApi from "../../api/VouOthChApi";
import VoucherApi from "../../api/VoucherApi";
import CustomerSelectDialog from "../../dialogs/CustomerSelectDialog";
import MessageDialog from "../../dialogs/MessageDialog";
import { CalcOnTypeEnum } from "../../enums/CalcOnTypeEnum";
import { VoucherTypeEnum } from "../../enums/VoucherTypeEnum";
import AcctJournalSumHelper from "../../helpers/AcctJournalSumHelper";
import CountryHelper from "../../helpers/CountryHelper";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import EstimateHelper from "../../helpers/EstimateHelper";
import EstimateItemHelper from "../../helpers/EstimateItemHelper";
import EstimateStatusHelper from "../../helpers/EstimateStatusHelper";
import PaymentModeHelper from "../../helpers/PaymentModeHelper";
import PaymentStatusHelper from "../../helpers/PaymentStatusHelper";
import PaymentTermHelper from "../../helpers/PaymentTermHelper";
import ProductHelper from "../../helpers/ProductHelper";
import SalesChannelHelper from "../../helpers/SalesChannelHelper";
import StateHelper from "../../helpers/StateHelper";
import TaxCatHelper from "../../helpers/TaxCatHelper";
import TaxHelper from "../../helpers/TaxHelper";
import TncHelper from "../../helpers/TncHelper";
import TradeTermHelper from "../../helpers/TradeTermHelper";
import TransportModeHelper from "../../helpers/TransportModeHelper";
import TransporterHelper from "../../helpers/TransporterHelper";
import UnitHelper from "../../helpers/UnitHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import DropDownItemType from "../../types/DropDownItemType";
import EstimateItemType from "../../types/EstimateItemType";
import VouOthChNonTaxableType from "../../types/VouOthChNonTaxableType";
import VouOthChOnItemType from "../../types/VouOthChOnItemType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import CountryLdto from "../../types/res/list/CountryLdto";
import CustomerListResType from "../../types/res/list/CustomerListResType";
import EstimateStatusListResType from "../../types/res/list/EstimateStatusListResType";
import ListAcctForCustomerResType from "../../types/res/list/ListAcctForCustomerResType";
import ListVouOthChargeResType from "../../types/res/list/ListVouOthChargeResType";
import PaymentModeListResType from "../../types/res/list/PaymentModeListResType";
import PaymentStatusListResType from "../../types/res/list/PaymentStatusListResType";
import PaymentTermListResType from "../../types/res/list/PaymentTermListResType";
import ProdForEsimateListResType from "../../types/res/list/ProdForEsimateListResType";
import SalesChannelListResType from "../../types/res/list/SalesChannelListResType";
import StateListResType from "../../types/res/list/StateListResType";
import TaxCatListResType from "../../types/res/list/TaxCatListResType";
import TaxListResType from "../../types/res/list/TaxListResType";
import TncListResType from "../../types/res/list/TncListResType";
import TradeTermListResType from "../../types/res/list/TradeTermListResType";
import TransportModeListResType from "../../types/res/list/TransportModeListResType";
import TransporterListResType from "../../types/res/list/TransporterListResType";
import UnitListResType from "../../types/res/list/UnitListResType";
import EstimateRrHoDto from "../../types/rr/EstimateRrHoDto";
import DownshiftSelect from "../DownshiftSelectV2";
import TextBox from "../controls/TextBox";
import AddEstimateItemFormSv from "./AddEstimateItemFormSv";
import AddEstimateItemsTable from "./AddEstimateItemsTable";
import AddEstimateOthChNonTaxableSv from "./AddEstimateOthChNonTaxableSv";
import AddEstimateOthChOnItemSv from "./AddEstimateOthChOnItemSv";
import AddEstimateTaxSv from "./AddEstimateTaxSv";
import AddEstimateTotalSv from "./AddEstimateTotalSv";

interface AddEstimateProps {
	id?: number
}

function AddEstimate({
	id
}: AddEstimateProps): JSX.Element {

	const [acctForCustomer, setAcctForCustomer] = useState<ListAcctForCustomerResType | null>(null);

	const [noStr, setNoStr] = useState("");
	const [date, setDate] = useState<Date | null>(new Date());

	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [stateId, setStateId] = useState<number | null>(null);
	const [countryId, setCountryId] = useState<number | null>(null);
	const [pin, setPin] = useState("");
	const [email, setEmail] = useState("");
	const [gstNo, setGstNo] = useState("");
	const [contactNo, setContactNo] = useState("");
	const [isShippingSameAsBilling, setIsShippingSameAsBilling] = useState(true);
	const [shippingName, setShippingName] = useState("");
	const [shippingAddress, setShippingAddress] = useState("");
	const [shippingCity, setShippingCity] = useState("");
	const [shippingStateId, setShippingStateId] = useState<number | null>(null);
	const [shippingCountryId, setShippingCountryId] = useState<number | null>(null);
	const [shippingPin, setShippingPin] = useState("");
	const [shippingEmail, setShippingEmail] = useState("");
	const [shippingGstNo, setShippingGstNo] = useState("");
	const [shippingContactNo, setShippingContactNo] = useState("");

	const [paymentModeId, setPaymentModeId] = useState<number | null>(null);
	const [paymentTermId, setPaymentTermId] = useState<number | null>(null);
	const [salesChannelId, setSalesChannelId] = useState<number | null>(null);
	const [tradeTermId, setTradeTermId] = useState<number | null>(null);
	const [transporterId, setTransporterId] = useState<number | null>(null);
	const [currencyId, setCurrencyId] = useState<number | null>(null);
	const [currencyConversionRateStr, setCurrencyConversionRateStr] = useState("");
	const [trackingNo, setTrackingNo] = useState("");
	const [paymentStatusId, setPaymentStatusId] = useState<number | null>(null);
	const [transportModeId, setTransportModeId] = useState<number | null>(null);
	const [dap, setDap] = useState("");
	const [statusId, setStatusId] = useState<number | null>(null);
	const [lockStockForDaysStr, setLockStockForDaysStr] = useState("");
	const [note, setNote] = useState("");
	const [isLut, setIsLut] = useState(false);
	const [tncId, setTncId] = useState<number | null>(null);

	const [creditDaysLimit, setCreditDaysLimit] = useState<string>("");
	const [creditDaysCurrentStatus, setCreditDaysCurrentStatus] = useState<string>("");
	const [overdueBal, setOverdueBal] = useState<string>("");
	const [isCreditDaysRight, setIsCreditDaysRight] = useState<boolean>(true);

	const [creditAmt, setCreditAmt] = useState<string>("");
	const [currentBal, setCurrentBal] = useState<string>("");
	const [currentBalDiff, setCurrentBalDiff] = useState<string>("");
	const [isCreditAmtRight, setIsCreditAmtRight] = useState<boolean>(true);

	const [estimateItems, setEstimateItems] = useState<EstimateItemType[]>([]);
	const [estimateOthChOnItemArr, setEstimateOthChOnItemArr] = useState<VouOthChOnItemType[]>([]);

	const [isAddTax, setIsAddTax] = useState<boolean>(true);
	const [isIntra, setIsIntra] = useState<boolean>(true);

	const [estimateOthChNonTaxableArr, setEstimateOthChNonTaxableArr] = useState<VouOthChNonTaxableType[]>([]);
	//
	const [customers, setCustomers] = useState<CustomerListResType[]>([]);

	const [paymentModeListResArr, setPaymentModeListArr] = useState<PaymentModeListResType[]>([]);
	const [paymentModeListResArrForDd, setPaymentModeListArrForDd] = useState<DropDownItemType[]>([]);
	const [paymentModeListResMap, setPaymentModeListResMap] = useState<Map<number, PaymentModeListResType>>(new Map());

	const [paymentTermListResArr, setPaymentTermListArr] = useState<PaymentTermListResType[]>([]);
	const [paymentTermListResArrForDd, setPaymentTermListArrForDd] = useState<DropDownItemType[]>([]);
	const [paymentTermListResMap, setPaymentTermListResMap] = useState<Map<number, PaymentTermListResType>>(new Map());

	const [salesChannelListResArr, setSalesChannelListArr] = useState<SalesChannelListResType[]>([]);
	const [salesChannelListResArrForDd, setSalesChannelListArrForDd] = useState<DropDownItemType[]>([]);
	const [salesChannelListResMap, setSalesChannelListResMap] = useState<Map<number, SalesChannelListResType>>(new Map());

	const [tradeTermListResArr, setTradeTermListArr] = useState<TradeTermListResType[]>([]);
	const [tradeTermListResArrForDd, setTradeTermListArrForDd] = useState<DropDownItemType[]>([]);
	const [tradeTermListResMap, setTradeTermListResMap] = useState<Map<number, TradeTermListResType>>(new Map());

	const [paymentStatusListResArr, setPaymentStatusListArr] = useState<PaymentStatusListResType[]>([]);
	const [paymentStatusListResArrForDd, setPaymentStatusListArrForDd] = useState<DropDownItemType[]>([]);
	const [paymentStatusListResMap, setPaymentStatusListResMap] = useState<Map<number, PaymentStatusListResType>>(new Map());

	const [transportModeListResArr, setTransportModeListArr] = useState<TransportModeListResType[]>([]);
	const [transportModeListResArrForDd, setTransportModeListArrForDd] = useState<DropDownItemType[]>([]);
	const [transportModeListResMap, setTransportModeListResMap] = useState<Map<number, TransportModeListResType>>(new Map());

	const [tncListResArr, setTncListArr] = useState<TncListResType[]>([]);
	const [tncListResArrForDd, setTncListArrForDd] = useState<DropDownItemType[]>([]);
	const [tncListResMap, setTncListResMap] = useState<Map<number, TncListResType>>(new Map());

	const [transporterListResArr, setTransporterListArr] = useState<TransporterListResType[]>([]);
	const [transporterListResArrForDd, setTransporterListArrForDd] = useState<DropDownItemType[]>([]);
	const [transporterListResMap, setTransporterListResMap] = useState<Map<number, TransporterListResType>>(new Map());

	const [vouOthChOnItemResArr, setVouOthChOnItemResArr] = useState<ListVouOthChargeResType[]>([]);
	const [vouOthChOnItemResArrForDd, setVouOthChOnItemResArrForDd] = useState<DropDownItemType[]>([]);
	const [vouOthChOnItemResMap, setVouOthChOnItemResMap] = useState<Map<number, ListVouOthChargeResType>>(new Map());

	const [vouOthChNonTaxableResArr, setVouOthChNonTaxableResArr] = useState<ListVouOthChargeResType[]>([]);
	const [vouOthChNonTaxableResArrForDd, setVouOthChNonTaxableResArrForDd] = useState<DropDownItemType[]>([]);
	const [vouOthChNonTaxableResMap, setVouOthChNonTaxableResMap] = useState<Map<number, ListVouOthChargeResType>>(new Map());

	const [estimateStatusListResArr, setEstimateStatusListArr] = useState<EstimateStatusListResType[]>([]);
	const [estimateStatusListResArrForDd, setEstimateStatusListArrForDd] = useState<DropDownItemType[]>([]);
	const [estimateStatusListResMap, setEstimateStatusListResMap] = useState<Map<number, EstimateStatusListResType>>(new Map());

	const [taxListResArr, setTaxListArr] = useState<TaxListResType[]>([]);
	const [taxListResArrForDd, setTaxListArrForDd] = useState<DropDownItemType[]>([]);
	const [taxListResMap, setTaxListResMap] = useState<Map<number, TaxListResType>>(new Map());

	const [taxCatListResArr, setTaxCatListArr] = useState<TaxCatListResType[]>([]);
	const [taxCatListResArrForDd, setTaxCatListArrForDd] = useState<DropDownItemType[]>([]);
	const [taxCatListResMap, setTaxCatListResMap] = useState<Map<number, TaxCatListResType>>(new Map());

	const [unitListResArr, setUnitListArr] = useState<UnitListResType[]>([]);
	const [unitListResArrForDd, setUnitListArrForDd] = useState<DropDownItemType[]>([]);
	const [unitListResMap, setUnitListResMap] = useState<Map<number, UnitListResType>>(new Map());

	const [productForEstimateListResArr, setProductForEstimateListResArr] = useState<ProdForEsimateListResType[]>([]);
	const [productForEstimateListResArrForDd, setProductForEstimateListResArrForDd] = useState<DropDownItemType[]>([]);
	const [productForEstimateListResMap, setProductsForEstimateListResMap] = useState<Map<number, ProdForEsimateListResType>>(new Map());

	const [countryListResArr, setCountryListResArr] = useState<CountryLdto[]>([]);
	const [countryDdis, setCountryDdis] = useState<DropDownItemType[]>([]);
	const [currencyArrForDd, setCurrencyArrForDd] = useState<DropDownItemType[]>([]);
	const [countryListResMap, setCountryListResMap] = useState<Map<number, CountryLdto>>(new Map());

	const [stateListResArr, setStateListArr] = useState<StateListResType[]>([]);
	const [stateListResArrForDd, setStateListArrForDd] = useState<DropDownItemType[]>([]);
	const [stateListResMap, setStateListResMap] = useState<Map<number, StateListResType>>(new Map());
	//
	const [showCustomerDialog, setShowCustomerDialog] = useState(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			PaymentModeApi.getPaymentModes().then((response) => {
				if (response.isSuccess) {
					setPaymentModeListArr(response.items!);
					setPaymentModeListArrForDd(PaymentModeHelper.getPaymentModeListResArrForDd(response.items!));
					setPaymentModeListResMap(PaymentModeHelper.getPaymentModeListResMap(response.items!));
				}
			});

			PaymentTermApi.getPaymentTerms().then((response) => {
				if (response.isSuccess) {
					setPaymentTermListArr(response.items!);
					setPaymentTermListArrForDd(PaymentTermHelper.getPaymentTermListResArrForDd(response.items!));
					setPaymentTermListResMap(PaymentTermHelper.getPaymentTermListResMap(response.items!));
				}
			});

			SalesChannelApi.getSalesChannels().then((response) => {
				if (response.isSuccess) {
					setSalesChannelListArr(response.items!);
					setSalesChannelListArrForDd(SalesChannelHelper.getSalesChannelListResArrForDd(response.items!));
					setSalesChannelListResMap(SalesChannelHelper.getSalesChannelListResMap(response.items!));
				}
			});

			TradeTermApi.getTradeTerms().then((response) => {
				if (response.isSuccess) {
					setTradeTermListArr(response.items!);
					setTradeTermListArrForDd(TradeTermHelper.getTradeTermListResArrForDd(response.items!));
					setTradeTermListResMap(TradeTermHelper.getTradeTermListResMap(response.items!));
				}
			});

			PaymentStatusApi.getPaymentStatuss().then((response) => {
				if (response.isSuccess) {
					setPaymentStatusListArr(response.items!);
					setPaymentStatusListArrForDd(PaymentStatusHelper.getPaymentStatusListResArrForDd(response.items!));
					setPaymentStatusListResMap(PaymentStatusHelper.getPaymentStatusListResMap(response.items!));
				}
			});

			TransportModeApi.getTransportModes().then((response) => {
				if (response.isSuccess) {
					setTransportModeListArr(response.items!);
					setTransportModeListArrForDd(TransportModeHelper.getTransportModeListResArrForDd(response.items!));
					setTransportModeListResMap(TransportModeHelper.getTransportModeListResMap(response.items!));
				}
			});

			TncApi.getTncs().then((response) => {
				if (response.isSuccess) {
					setTncListArr(response.items!);
					setTncListArrForDd(TncHelper.getTncListResArrForDd(response.items!));
					setTncListResMap(TncHelper.getTncListResMap(response.items!));
				}
			});

			TransporterApi.getTransporters().then((response) => {
				if (response.isSuccess) {
					setTransporterListArr(response.items!);
					setTransporterListArrForDd(TransporterHelper.getTransporterListResArrForDd(response.items!));
					setTransporterListResMap(TransporterHelper.getTransporterListResMap(response.items!));
				}
			});

			VouOthChApi.getVouOthCh(CalcOnTypeEnum.ITEM, false).then((response) => {
				if (response.isSuccess) {
					setVouOthChOnItemResArr(response.items!);
					setVouOthChOnItemResArrForDd(VouOthChHelper.getVouOthChArrForDd(response.items!));
					setVouOthChOnItemResMap(VouOthChHelper.getVouOthChListResMap(response.items!));
				}
			});

			VouOthChApi.getVouOthCh(CalcOnTypeEnum.VOUCHER, false).then((response) => {
				if (response.isSuccess) {
					setVouOthChNonTaxableResArr(response.items!);
					setVouOthChNonTaxableResArrForDd(VouOthChHelper.getVouOthChArrForDd(response.items!));
					setVouOthChNonTaxableResMap(VouOthChHelper.getVouOthChListResMap(response.items!));
				}
			});

			EstimateStatusApi.getEstimateStatuses().then((response) => {
				if (response.isSuccess) {
					setEstimateStatusListArr(response.items!);
					setEstimateStatusListArrForDd(EstimateStatusHelper.getEstimateStatusListResArrForDd(response.items!));
					setEstimateStatusListResMap(EstimateStatusHelper.getEstimateStatusListResMap(response.items!));
				}
			});

			TaxApi.getTaxes().then((response) => {
				if (response.isSuccess) {
					setTaxListArr(response.items!);
					setTaxListArrForDd(TaxHelper.getTaxListForDd(response.items!));
					setTaxListResMap(TaxHelper.getTaxMap(response.items!));
				}
			});

			TaxCatApi.getTaxCats().then((response) => {
				if (response.isSuccess) {
					setTaxCatListArr(response.items!);
					setTaxCatListArrForDd(TaxCatHelper.getTaxCatsForDd(response.items!));
					setTaxCatListResMap(TaxCatHelper.getTaxCatListResMap(response.items!));
				}
			});

			UnitApi.getUnits().then((response) => {
				if (response.isSuccess) {
					setUnitListArr(response.items!);
					setUnitListArrForDd(UnitHelper.getUnitsForDd(response.items!));
					setUnitListResMap(UnitHelper.getUnitMap(response.items!));
				}
			});

			ProductApi.getProdForEstimate().then((response) => {
				if (response.isSuccess) {
					setProductForEstimateListResArr(response.items!);
					setProductForEstimateListResArrForDd(ProductHelper.getProductsForDd(response.items!));
					setProductsForEstimateListResMap(ProductHelper.getProductMap2(response.items!));
				}
			});

			CountryApi.getCountryLdtos().then((response) => {
				if (response.isSuccess) {
					setCountryListResArr(response.items!);
					setCurrencyArrForDd(CurrencyHelper.getCurrencyArrForDd(response.items!));
					setCountryListResMap(CountryHelper.getCountryLdtoMap(response.items!));
				}
			});

			StateApi.getStates().then((response) => {
				if (response.isSuccess) {
					setStateListArr(response.items!);
					setStateListArrForDd(StateHelper.getStateListResArrForDd(response.items!));
					setStateListResMap(StateHelper.getStateListResMap(response.items!));
				}
			});

			if (id !== undefined && id !== null) {
				// fillInUi(await EstimateApi.getEsimate(id));
			}
			else {
				setNoStr(await VoucherApi.getNextNo(VoucherTypeEnum.SALES));
			}

		}
		process();
	}, []);

	const handleItemChange = (addEstimateItem: EstimateItemType) => {
		setEstimateItems(EstimateItemHelper.calItems(isAddTax, isIntra, taxListResMap, taxCatListResMap, productForEstimateListResMap, [...estimateItems, addEstimateItem], estimateOthChOnItemArr));
	}

	const handleOthChOnItemChange = (estimateOthChOnItemArr: VouOthChOnItemType[]) => {
		setEstimateItems(EstimateItemHelper.calItems(isAddTax, isIntra, taxListResMap, taxCatListResMap, productForEstimateListResMap, estimateItems, estimateOthChOnItemArr));
		setEstimateOthChOnItemArr(estimateOthChOnItemArr);
	}

	const handleOthChNonTaxableChange = (estimateOthChNonTaxableArr: VouOthChNonTaxableType[]) => {
		EstimateHelper.calOthChNonTax(estimateItems, estimateOthChNonTaxableArr);
		setEstimateOthChNonTaxableArr(estimateOthChNonTaxableArr);
	}

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let estimateReqHo: EstimateRrHoDto = {
			estimateRrDto: {
				date: date!,
				no: noStr,
				customerId: acctForCustomer?.id!,
				name: name,
				address: address,
				city: city,
				stateId: stateId,
				countryId: countryId,
				pin: pin,
				email: email,
				contactNo: contactNo,
				isShippingSameAsBilling: isShippingSameAsBilling,
				shippingName: shippingName,
				shippingAddress: shippingAddress,
				shippingCity: shippingCity,
				shippingStateId: shippingStateId,
				shippingCountryId: shippingCountryId,
				shippingPin: shippingPin,
				shippingEmail: shippingEmail,
				shippingContactNo: shippingContactNo,
				isIntra: isIntra,
				transporterId: transporterId === -1 ? null : transporterId,
				isTax: isAddTax,
				note: note,
				statusId: statusId === -1 ? null : statusId,
				paymentModeId: paymentModeId === -1 ? null : paymentModeId,
				paymentTermId: paymentTermId === -1 ? null : paymentTermId,
				salesChannelId: salesChannelId === -1 ? null : salesChannelId,
				tradeTermId: tradeTermId === -1 ? null : tradeTermId,
				currencyId: currencyId === -1 ? null : currencyId,
				currencyConversionRate: currencyConversionRateStr.trim() === "" ? null : parseFloat(currencyConversionRateStr),
				lockStatusForDays: lockStockForDaysStr.trim() === "" ? null : parseInt(lockStockForDaysStr),
				trackingNo: trackingNo,
				paymentStatusId: paymentStatusId,
				isLut: isLut,
				transportModeId: transportModeId,
				dap: dap,
				tncId: tncId
			},
			estimateItemRrDtos: estimateItems,
			estimateOthChOnItemRrDtos: estimateOthChOnItemArr,
			estimateOthChTaxableRrDtos: [],
			estimateOthChNonTaxableRrDtos: estimateOthChNonTaxableArr,
		}

		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await EstimateApi.createEstimate(estimateReqHo);
		}
		else {
			res = await EstimateApi.updateEstimate(id, estimateReqHo);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (/*transporterResType: EstimateResType*/) => {

		setNoStr("");
		setDate(new Date());
		setAcctForCustomer(null);
		setIsIntra(true);
		setTransporterId(-1);
		setIsAddTax(true);
		setNote("");
		setStatusId(-1);
		setPaymentModeId(-1);
		setPaymentTermId(-1);
		setSalesChannelId(-1);
		setTradeTermId(-1);
		setCurrencyId(-1);
		setCurrencyConversionRateStr("");
		setLockStockForDaysStr("");


		setCreditDaysLimit("");
		setCreditDaysCurrentStatus("");
		setOverdueBal("");

		setCreditAmt("");
		setCurrentBal("");
		setCurrentBalDiff("");
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {

		setNoStr("");
		setDate(new Date());
		setAcctForCustomer(null);
		setIsIntra(true);
		setTransporterId(-1);
		setIsAddTax(true);
		setNote("");
		setStatusId(-1);
		setPaymentModeId(-1);
		setPaymentTermId(-1);
		setSalesChannelId(-1);
		setTradeTermId(-1);
		setCurrencyId(-1);
		setCurrencyConversionRateStr("");
		setLockStockForDaysStr("");


		setCreditDaysLimit("");
		setCreditDaysCurrentStatus("");
		setOverdueBal("");

		setCreditAmt("");
		setCurrentBal("");
		setCurrentBalDiff("");
	};


	const mySetAcctForCustomer = (acctForCustomerSelected: ListAcctForCustomerResType | null) => {

		let creditDaysLimitLoc = "";
		let creditDaysCurrentStatusLoc = "";
		let overdueBalLoc = "";
		let isCreditDaysRightLoc = false;

		let creditAmtLoc = "";
		let currentBalLoc = "";
		let currenBalDiffLoc = "";
		let isCreditAmtRightLoc = false;

		if (acctForCustomerSelected !== null) {

			setIsIntra(13 === acctForCustomerSelected.stateId)

			if (acctForCustomerSelected.creditDays !== undefined
				&& acctForCustomerSelected.creditDays !== null) {
				creditDaysLimitLoc = acctForCustomerSelected.creditDays.toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.overdueDays !== undefined
				&& acctForCustomerSelected.overdueDays !== null
			) {
				creditDaysCurrentStatusLoc = acctForCustomerSelected.overdueDays.toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.overdueAmt !== undefined
				&& acctForCustomerSelected.overdueAmt !== null
				&& acctForCustomerSelected.overdueAmt !== 0
			) {
				overdueBalLoc = acctForCustomerSelected.overdueAmt.toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.overdueAmt !== undefined
				&& acctForCustomerSelected.overdueAmt !== null
				&& acctForCustomerSelected.overdueAmt <= 0
			) {
				isCreditDaysRightLoc = true;
			}

			//

			if (acctForCustomerSelected.creditAmtLimit !== undefined
				&& acctForCustomerSelected.creditAmtLimit !== null

			) {
				creditAmtLoc = acctForCustomerSelected.creditAmtLimit.toLocaleString('en-IN');
			}

			let acctJournalSumHelper = new AcctJournalSumHelper(acctForCustomerSelected.sumDebit,
				acctForCustomerSelected.sumCredit);

			if (acctJournalSumHelper.isCrDrSame()) {
				currentBalLoc = "0";
			}
			else if (acctJournalSumHelper.isCrBig()) {
				currentBalLoc = " - " + acctJournalSumHelper.getCrBal().toLocaleString('en-IN');
			}
			else if (acctJournalSumHelper.isDrBig()) {
				currentBalLoc = acctJournalSumHelper.getDrBal().toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.creditAmtLimit !== null) {
				acctJournalSumHelper.credit = acctJournalSumHelper.credit + acctForCustomerSelected.creditAmtLimit;
			}

			let acctJournalSumHelperTemp = new AcctJournalSumHelper(
				acctJournalSumHelper.debit,
				acctJournalSumHelper.credit
			);

			currenBalDiffLoc = acctJournalSumHelperTemp.getCrBal().toLocaleString('en-IN');

			if (acctJournalSumHelperTemp.getCrBal() >= 0) {
				isCreditAmtRightLoc = true;
			}

		}

		setCreditDaysLimit(creditDaysLimitLoc);
		setCreditDaysCurrentStatus(creditDaysCurrentStatusLoc);
		setOverdueBal(overdueBalLoc);
		setIsCreditDaysRight(isCreditDaysRightLoc);

		setCreditAmt(creditAmtLoc);
		setCurrentBal(currentBalLoc);
		setCurrentBalDiff(currenBalDiffLoc);
		setIsCreditAmtRight(isCreditAmtRightLoc);

		setAcctForCustomer(acctForCustomerSelected)
	}

	const isValid = (): boolean => {


		return true;
	}

	return (
		<>
			<div className="container-fluid">

				<form onSubmit={(event) => handleClickSave(event)}>

					<div className="row col-sm-6">

						<TextBox label="Number" value={noStr} onChange={setNoStr} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={date}
									onChange={(e) => setDate(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCustomer" className="col-form-label">Customer</label>
							</div>
							<div className="col-sm-10">
								<div className="input-group">
									<input
										id="txtCustomer"
										type="text"
										className="form-control"
										value={acctForCustomer?.name ?? ""}
										readOnly={true}
									/>
									<button
										className="btn btn-outline-secondary" type="button"
										onClick={(e) => setShowCustomerDialog(true)}
									>...</button>
								</div>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtAddress" className="col-form-label">Address</label>
							</div>
							<div className="col-sm-10">
								<textarea
									id="txtAddress"
									className="form-control"
									rows={3}
									value={acctForCustomer?.address ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCity" className="col-form-label">City</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtCity"
									type="text"
									className="form-control"
									value={acctForCustomer?.city ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">State</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={acctForCustomer?.stateName}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Country</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={countryListResMap.get(acctForCustomer?.countryId!)?.name}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">PIN</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={acctForCustomer?.pin}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Email</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={acctForCustomer?.email}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Contact No.</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={acctForCustomer?.contactNo}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
							</div>
							<div className="col-sm-10">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										checked={isShippingSameAsBilling}
										onChange={(e) => setIsShippingSameAsBilling(e.target.checked)}
										id="flexCheckDefault"
									/>
									<label
										className="form-check-label"
										htmlFor="flexCheckDefault">
										Shpping address same as billing address
									</label>
								</div>
							</div>
						</div>

						<TextBox
							label="Shipping Name"
							value={isShippingSameAsBilling ? acctForCustomer?.name ?? "" : shippingName}
							onChange={setShippingName}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping Address"
							value={isShippingSameAsBilling ? acctForCustomer?.address ?? "" : shippingAddress}
							onChange={setShippingAddress}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping City"
							value={isShippingSameAsBilling ? acctForCustomer?.city ?? "" : shippingCity}
							onChange={setShippingCity}
							disabled={isShippingSameAsBilling}
						/>

						<div className="row align-items-center mb-3" >
							<div className="col-sm-2">
								<label htmlFor="cmbCustomer" className="col-form-label">Shipping Country</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCustomer"}
									value={
										isShippingSameAsBilling ?
											acctForCustomer != null
												&& acctForCustomer.countryId != null
												&& countryListResMap.get(acctForCustomer.countryId) != null ?
												CountryHelper.getCountryForDd(countryListResMap.get(acctForCustomer.countryId)!)
												: null
											:
											shippingCountryId != null && countryListResMap?.get(shippingCountryId) !== null ? CountryHelper.getCountryForDd(countryListResMap?.get(shippingCountryId)!) : null}
									onChange={setShippingCountryId}
									options={countryDdis}
									isLoading={false}
									isDisabled={isShippingSameAsBilling}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbState" className="col-form-label">Shipping State</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbState"}
									value={
										isShippingSameAsBilling ?
											stateId != null && stateListResMap.get(stateId) != null
												?
												StateHelper.getStateListResForDd(stateListResMap?.get(stateId)!)
												:
												null
											:
											shippingStateId != null && stateListResMap.get(shippingStateId) != null
												?
												StateHelper.getStateListResForDd(stateListResMap.get(shippingStateId)!)
												: null
									}
									onChange={setShippingStateId}
									options={stateListResArrForDd}
									isLoading={false}
									isDisabled={isShippingSameAsBilling}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox
							label="Shipping PIN"
							value={isShippingSameAsBilling ? pin : shippingPin}
							onChange={setShippingPin}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping Email"
							value={isShippingSameAsBilling ? email : shippingEmail}
							onChange={setShippingEmail}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping GST No."
							value={isShippingSameAsBilling ? gstNo : shippingGstNo}
							onChange={setShippingGstNo}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping Contact No."
							value={isShippingSameAsBilling ? "" : shippingContactNo}
							onChange={setShippingContactNo}
							disabled={isShippingSameAsBilling}
						/>


						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTransporter" className="col-form-label">Transporter</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTransporter"}
									value={transporterId == null || transporterListResMap.get(transporterId) == null ? null : TransporterHelper.getTransporterListResForDd(transporterListResMap.get(transporterId)!)}
									onChange={setTransporterId}
									options={transporterListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbStatusId" className="col-form-label">Status</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbStatusId"}
									value={statusId == null || estimateStatusListResMap.get(statusId) == null ? null : EstimateStatusHelper.getEstimateStatusListResForDd(estimateStatusListResMap.get(statusId)!)}
									onChange={setStatusId}
									options={estimateStatusListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbPaymentModeId" className="col-form-label">Payment Mode</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbPaymentModeId"}
									value={paymentModeId == null || paymentModeListResMap.get(paymentModeId) == null ? null : PaymentModeHelper.getPaymentModeListResForDd(paymentModeListResMap.get(paymentModeId)!)}
									onChange={setPaymentModeId}
									options={paymentModeListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbPaymentTermId" className="col-form-label">Payment Term</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbPaymentTermId"}
									value={paymentTermId == null || paymentTermListResMap.get(paymentTermId) == null ? null : PaymentTermHelper.getPaymentTermListResForDd(paymentTermListResMap.get(paymentTermId)!)}
									onChange={setPaymentTermId}
									options={paymentTermListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbSalesChannelId" className="col-form-label">Sales Channel</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbSalesChannelId"}
									value={salesChannelId == null || salesChannelListResMap.get(salesChannelId) == null ? null : SalesChannelHelper.getSalesChannelListResForDd(salesChannelListResMap.get(salesChannelId)!)}
									onChange={setSalesChannelId}
									options={salesChannelListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTradeTermId" className="col-form-label">Trade Term</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTradeTermId"}
									value={tradeTermId == null || tradeTermListResMap.get(tradeTermId) == null ? null : TradeTermHelper.getTradeTermListResForDd(tradeTermListResMap.get(tradeTermId)!)}
									onChange={setTradeTermId}
									options={tradeTermListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbCurrencyId" className="col-form-label">Currency</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCurrencyId"}
									value={currencyId == null || countryListResMap.get(currencyId) == null ? null : CurrencyHelper.getCurrencyForDd(countryListResMap.get(currencyId)!)}
									onChange={setCurrencyId}
									options={currencyArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox label="Conversion Rate" value={currencyConversionRateStr} onChange={setCurrencyConversionRateStr} />

						<TextBox label="Lock Stock for Days" value={lockStockForDaysStr} onChange={setLockStockForDaysStr} />

						<TextBox label="Tracking No." value={trackingNo} onChange={setTrackingNo} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbPaymentStatusId" className="col-form-label">Payment Status</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbPaymentStatusId"}
									value={paymentStatusId == null || paymentStatusListResMap.get(paymentStatusId) == null ? null : PaymentStatusHelper.getPaymentStatusListResForDd(paymentStatusListResMap.get(paymentStatusId)!)}
									onChange={setPaymentStatusId}
									options={paymentStatusListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTransportModeId" className="col-form-label">Transport Mode</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTransportModeId"}
									value={transportModeId == null || transportModeListResMap.get(transportModeId) == null ? null : TransportModeHelper.getTransportModeListResForDd(transportModeListResMap.get(transportModeId)!)}
									onChange={setTransportModeId}
									options={transportModeListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox label="DAP" value={dap} onChange={setDap} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTncId" className="col-form-label">Terms & Condi.</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTncId"}
									value={tncId == null || tncListResMap.get(tncId) == null ? null : TncHelper.getTncListResForDd(tncListResMap.get(tncId)!)}
									onChange={setTncId}
									options={tncListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox label="Note" value={note} onChange={setNote} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
							</div>
							<div className="col-sm-10">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										checked={isLut}
										onChange={(e) => setIsLut(e.target.checked)}
										id="chkLut"
									/>
									<label
										className="form-check-label"
										htmlFor="chkLut">
										LUT
									</label>
								</div>
							</div>
						</div>

					</div>

					<div className="row mb-2">
						<div className="col-md-2">
							&nbsp;
						</div>
						<div className="col-md-3">
							Limit
						</div>
						<div className="col-md-3">
							Current Status
						</div>
						<div className="col-md-3">
							Balance
						</div>
						<div className="col-md-3">
							&nbsp;
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-2">
							Credit Days
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={creditDaysLimit}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={creditDaysCurrentStatus}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={overdueBal}
								readOnly={true}
							/>
						</div>
						<div className="col-md-1">
							{isCreditDaysRight ? "" : "X"}
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-2">
							Credit Amount
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={creditAmt}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={currentBal}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={currentBalDiff}
								readOnly={true}
							/>
						</div>
						<div className="col-md-1">
							{isCreditAmtRight ? "" : "X"}
						</div>
					</div>



					<Tabs
						defaultActiveKey="tab-item-details"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="tab-item-details" title="Item Details">
							<AddEstimateItemFormSv
								productsForDd={productForEstimateListResArrForDd}
								productsMap={productForEstimateListResMap}
								handleItemChange={handleItemChange}
								currencyConversionRateStr={currencyConversionRateStr}
							/>

							<AddEstimateItemsTable
								taxMap={taxListResMap}
								taxCatMap={taxCatListResMap}
								addEstimateItems={estimateItems}
								productsMap={productForEstimateListResMap}
								unitsMap={unitListResMap}
							/>
						</Tab>
						<Tab eventKey="tab-pending-items" title="Pending Items">
							Tab content for Profile
						</Tab>
					</Tabs>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={EstimateHelper.getSumAmtAfterItemDisc(estimateItems)}
								type="text"
								className="form-control"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<AddEstimateOthChOnItemSv
						vouOthChOnItemResMap={vouOthChOnItemResMap}
						vouOthChOnItemResArrForDd={vouOthChOnItemResArrForDd}
						vouOthChOnItemArr={estimateOthChOnItemArr}
						handleOthChOnItemChange={handleOthChOnItemChange}
					/>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={EstimateHelper.getSumItemTaxableAmt(estimateItems)}
								type="text"
								className="form-control"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<AddEstimateTaxSv
						isAddTax={isAddTax}
						setIsAddTax={setIsAddTax}
						isIntra={isIntra}
						taxAmtContainer={EstimateHelper.getTaxSum(estimateItems)}
					/>

					<hr />

					<AddEstimateOthChNonTaxableSv
						vouOthChNonTaxableResMap={vouOthChNonTaxableResMap}
						vouOthChNonTaxableResArrForDd={vouOthChNonTaxableResArrForDd}
						vouOthChNonTaxableArr={estimateOthChNonTaxableArr}
						handleOthChNonTaxableChange={handleOthChNonTaxableChange}
					/>

					<hr />

					<AddEstimateTotalSv
						totalAmtContainer={EstimateHelper.getTotalAmt(estimateItems, estimateOthChNonTaxableArr)}
					/>

					<button type="submit" className="btn btn-primary">Submit</button>

				</form>
			</div >

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />


			<CustomerSelectDialog
				showDialog={showCustomerDialog}
				setShowDialog={setShowCustomerDialog}
				setAcctForCustomer={mySetAcctForCustomer}
			/>

		</>
	);
}

export default AddEstimate;

import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import EstimateStatusListResType from "../types/res/list/EstimateStatusListResType";

export default class EstimateStatusApi {

	static async getEstimateStatuses(): Promise<CommonGetListType<EstimateStatusListResType>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.ESTIMATE_STATUS.name),
				requestOptions);

			if (response.status === 200) {
				const data: EstimateStatusListResType[] = await response.json();
				return {
					isSuccess: true,
					items: data
				};
			}
			else if (response.status === 400) {
				const data: ErrorResType = await response.json();
				return {
					isSuccess: false,
					errorRes: data
				};
			}
			else {
				return ServerSideErrorCommonCudObj;
			}
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}



}
export class MenuEnum {
	public static readonly COMMNC_BOOK = new MenuEnum("commnc_book", "Communication Book");
	public static readonly REMINDER = new MenuEnum("reminder", "Reminder");
	public static readonly COMMNC = new MenuEnum("commnc", "Communication");
	public static readonly LEAD = new MenuEnum("lead", "Lead");
	public static readonly ACCT_BOOK = new MenuEnum("acct_book", "Account Book");
	public static readonly DAY_BOOK = new MenuEnum("day_book", "Day Book");
	public static readonly CDP_JOURNAL_VOU = new MenuEnum("cdp_journal_vou", "Cash Discount / Penalty");
	public static readonly JOURNAL = new MenuEnum("journal", "Journal");
	public static readonly CONTRA = new MenuEnum("contra", "Contra");
	public static readonly RECEIPT = new MenuEnum("receipt", "Receipt");
	public static readonly PAYMENT = new MenuEnum("payment", "Payment");
	public static readonly SALES_RETURN = new MenuEnum("sales_return", "Sales Return");
	public static readonly SALES = new MenuEnum("sales", "Sales");
	public static readonly PI = new MenuEnum("pi", "Proforma Invoice");
	public static readonly SALES_ORDER = new MenuEnum("sales_order", "Sales Order");
	public static readonly ESTIMATE = new MenuEnum("estimate", "Estimate");
	public static readonly PURCHASE_RETURN = new MenuEnum("purchase_return", "Purchase Return");
	public static readonly PURCHASE = new MenuEnum("purchase", "Purchase");
	public static readonly PURCHASE_ORDER = new MenuEnum("purchase_order", "Purchase Order");
	public static readonly COMM_VOU = new MenuEnum("comm_vou", "Commission Voucher");
	public static readonly CDP_VOU = new MenuEnum("cdp_vou", "Cash Discount Voucher");
	public static readonly ORDER_ADJUST = new MenuEnum("order_adjust", "Order Adjust");
	public static readonly BANK = new MenuEnum("bank", "Bank");
	public static readonly CUSTOMER = new MenuEnum("customer", "Customer");
	public static readonly SUPPLIER = new MenuEnum("supplier", "Supplier");
	public static readonly AGENT = new MenuEnum("agent", "Agent");
	public static readonly ACCT = new MenuEnum("acct", "Account");
	public static readonly ACCT_TYPE = new MenuEnum("acct_type", "Account Type");
	public static readonly INV_ADJUST = new MenuEnum("inv_adjust", "Inventory Adjust");
	public static readonly COMM_LIST = new MenuEnum("comm_list", "Commission List");
	public static readonly PURCHASE_PRICE_LIST = new MenuEnum("purchase_price_list", "Purchase Price List");
	public static readonly SALES_PRICE_LIST = new MenuEnum("sales_price_list", "Sales Price List");
	public static readonly SERVICE = new MenuEnum("service", "Service");
	public static readonly PROD = new MenuEnum("prod", "Product");
	public static readonly PROD_OTH_CH = new MenuEnum("prod_oth_ch", "Product Other Charge");
	public static readonly TAX_CAT = new MenuEnum("tax_cat", "Tax Category");
	public static readonly CAT = new MenuEnum("cat", "Category");
	public static readonly UNIT = new MenuEnum("unit", "Unit");
	public static readonly RPT_SALES_RETURN_BY_MONTH = new MenuEnum("rpt_sales_return_by_month", "By Month");
	public static readonly RPT_SALES_RETURN_BY_DAY = new MenuEnum("rpt_sales_return_by_day", "By Day");
	public static readonly RPT_SALES_RETURN_BY_CUSTOMER = new MenuEnum("rpt_sales_return_by_customer", "By Customer");
	public static readonly RPT_SALES_RETURN_BY_CATEGORY = new MenuEnum("rpt_sales_return_by_category", "By Category");
	public static readonly RPT_SALES_RETURN_BY_PRODUCT = new MenuEnum("rpt_sales_return_by_product", "By Product");
	public static readonly RPT_SALES_RETURN_BY_CITY = new MenuEnum("rpt_sales_return_by_city", "By City");
	public static readonly RPT_SALES_RETURN_BY_STATE = new MenuEnum("rpt_sales_return_by_state", "By State");
	public static readonly RPT_SALES_RETURN_BY_AGENT = new MenuEnum("rpt_sales_return_by_agent", "By Agent");
	public static readonly RPT_SALES_RETURN_DETAILED_BY_VOUCHER = new MenuEnum("rpt_sales_return_detailed_by_voucher", "Detailed By Voucher");
	public static readonly RPT_SALES_RETURN_DETAILED_BY_PROD = new MenuEnum("rpt_sales_return_detailed_by_prod", "Detailed By Product");
	public static readonly RPT_SALES_BY_MONTH = new MenuEnum("rpt_sales_by_month", "By Month");
	public static readonly RPT_SALES_BY_DAY = new MenuEnum("rpt_sales_by_day", "By Day");
	public static readonly RPT_SALES_BY_CUSTOMER = new MenuEnum("rpt_sales_by_customer", "By Customer");
	public static readonly RPT_SALES_BY_CATEGORY = new MenuEnum("rpt_sales_by_category", "By Category");
	public static readonly RPT_SALES_BY_PRODUCT = new MenuEnum("rpt_sales_by_product", "By Product");
	public static readonly RPT_SALES_BY_CITY = new MenuEnum("rpt_sales_by_city", "By City");
	public static readonly RPT_SALES_BY_STATE = new MenuEnum("rpt_sales_by_state", "By State");
	public static readonly RPT_SALES_BY_AGENT = new MenuEnum("rpt_sales_by_agent", "By Agent");
	public static readonly RPT_SALES_DETAILED_BY_VOUCHER = new MenuEnum("rpt_sales_detailed_by_voucher", "Detailed By Voucher");
	public static readonly RPT_SALES_DETAILED_BY_PROD = new MenuEnum("rpt_sales_detailed_by_prod", "Detailed By Product");
	public static readonly SALES_ANALYSIS = new MenuEnum("sales_analysis", "Sales Analysis");
	public static readonly RPT_SALES_PROFIT_BY_CUSTOMER = new MenuEnum("rpt_sales_profit_by_customer", "By Customer");
	public static readonly RPT_SALES_PROFIT_BY_PROD = new MenuEnum("rpt_sales_profit_by_prod", "By Product");
	public static readonly RPT_SALES_PROFIT_BY_SALES_VOUCHER = new MenuEnum("rpt_sales_profit_by_sales_voucher", "By Sales Voucher");
	public static readonly RPT_SALES_PROFIT_BY_SALES_ITEM = new MenuEnum("rpt_sales_profit_by_sales_item", "By Sales Item");
	public static readonly RPT_PURCHASE_RETURN_BY_MONTH = new MenuEnum("rpt_purchase_return_by_month", "By Month");
	public static readonly RPT_PURCHASE_RETURN_BY_DAY = new MenuEnum("rpt_purchase_return_by_day", "By Day");
	public static readonly RPT_PURCHASE_RETURN_BY_SUPPLIER = new MenuEnum("rpt_purchase_return_by_supplier", "By Supplier");
	public static readonly RPT_PURCHASE_RETURN_BY_CATEGORY = new MenuEnum("rpt_purchase_return_by_category", "By Category");
	public static readonly RPT_PURCHASE_RETURN_BY_PRODUCT = new MenuEnum("rpt_purchase_return_by_product", "By Product");
	public static readonly RPT_PURCHASE_RETURN_BY_CITY = new MenuEnum("rpt_purchase_return_by_city", "By City");
	public static readonly RPT_PURCHASE_RETURN_BY_STATE = new MenuEnum("rpt_purchase_return_by_state", "By State");
	public static readonly RPT_PURCHASE_RETURN_DETAILED_BY_VOUCHER = new MenuEnum("rpt_purchase_return_detailed_by_voucher", "Detailed By Voucher");
	public static readonly RPT_PURCHASE_RETURN_DETAILED_BY_PROD = new MenuEnum("rpt_purchase_return_detailed_by_prod", "Detailed By Product");
	public static readonly RPT_PURCHASE_BY_MONTH = new MenuEnum("rpt_purchase_by_month", "By Month");
	public static readonly RPT_PURCHASE_BY_DAY = new MenuEnum("rpt_purchase_by_day", "By Day");
	public static readonly RPT_PURCHASE_BY_SUPPLIER = new MenuEnum("rpt_purchase_by_supplier", "By Supplier");
	public static readonly RPT_PURCHASE_BY_CATEGORY = new MenuEnum("rpt_purchase_by_category", "By Category");
	public static readonly RPT_PURCHASE_BY_PRODUCT = new MenuEnum("rpt_purchase_by_product", "By Product");
	public static readonly RPT_PURCHASE_BY_CITY = new MenuEnum("rpt_purchase_by_city", "By City");
	public static readonly RPT_PURCHASE_BY_STATE = new MenuEnum("rpt_purchase_by_state", "By State");
	public static readonly RPT_PURCHASE_DETAILED_BY_VOUCHER = new MenuEnum("rpt_purchase_detailed_by_voucher", "Detailed By Voucher");
	public static readonly RPT_PURCHASE_DETAILED_BY_PROD = new MenuEnum("rpt_purchase_detailed_by_prod", "Detailed By Product");
	public static readonly RPT_ESTIMATE_PENDING_BY_PROD = new MenuEnum("rpt_estimate_pending_by_prod", "By Product");
	public static readonly RPT_ESTIMATE_PENDING_BY_ACCT = new MenuEnum("rpt_estimate_pending_by_acct", "By Customer");
	public static readonly RPT_ESTIMATE_PENDING_PRODUCTS = new MenuEnum("rpt_estimate_pending_products", "Pending Products");
	public static readonly DUE_RCVABLE_BY_CUSTOMER = new MenuEnum("due_rcvable_by_customer", "By Customer");
	public static readonly DUE_RCVABLE_BY_SALES = new MenuEnum("due_rcvable_by_sales", "By Sales");
	public static readonly RPT_IO_SUMM_BY_PROD_CAT = new MenuEnum("rpt_io_summ_by_prod_cat", "By Category");
	public static readonly RPT_IO_SUMM_BY_MONTH = new MenuEnum("rpt_io_summ_by_month", "By Month");
	public static readonly RPT_IO_SUMM_BY_PROD = new MenuEnum("rpt_io_summ_by_prod", "By Product");
	public static readonly RPT_IO_DET = new MenuEnum("rpt_io_det", "In/Out Detailed");
	public static readonly LEDGER = new MenuEnum("ledger", "Ledger");
	public static readonly ACCT_SUMM_BY_ACCT_TYPE = new MenuEnum("acct_summ_by_acct_type", "Account Summary");
	public static readonly TRIAL_BAL = new MenuEnum("trial_bal", "Trial Balance");
	public static readonly TRADING_ACCT = new MenuEnum("trading_acct", "Trading Account");
	public static readonly PL_ACCT = new MenuEnum("pl_acct", "PL Account");
	public static readonly BALANCE_SHEET = new MenuEnum("balance_sheet", "Balance Sheet");
	public static readonly GSTR3B = new MenuEnum("gstr3b", "GSTR3B");
	public static readonly INVENTORY_LEDGER = new MenuEnum("inventory_ledger", "Inventory Ledger");
	public static readonly CURRENT_STOCK = new MenuEnum("current_stock", "Current Stock");
	public static readonly CURRENT_STOCK_VAL = new MenuEnum("current_stock_val", "Current Stock Value");
	public static readonly LOW_STOCK = new MenuEnum("low_stock", "Low Stock");
	public static readonly CDP_VOU_LEDGER = new MenuEnum("cdp_vou_ledger", "Cash Discount Voucher Ledger");
	public static readonly CDP_VOU_LEDGER_2 = new MenuEnum("cdp_vou_ledger_2", "Cash Discount Voucher Ledger 2");
	public static readonly COMM_VOU_LEDGER = new MenuEnum("comm_vou_ledger", "Commission Voucher Ledger");
	public static readonly SALES_ORDER_PENDING = new MenuEnum("sales_order_pending", "Sales Order Pending");
	public static readonly PURCHASE_ORDER_PENDING = new MenuEnum("purchase_order_pending", "Purchase Order Pending");
	public static readonly MSG_QUEUE = new MenuEnum("msg_queue", "Message Queue");
	public static readonly SCHEME = new MenuEnum("scheme", "Scheme");
	public static readonly TRANSPORTER = new MenuEnum("transporter", "Transporter");
	public static readonly TAX_CAL_RATE = new MenuEnum("tax_cal_rate", "Tax Calculation Rate");
	public static readonly TAX = new MenuEnum("tax", "Tax");
	public static readonly MSG_TEMPLATE = new MenuEnum("msg_template", "Message Template");
	public static readonly BROWSER = new MenuEnum("browser", "Browser");
	public static readonly COMPANY_INFO = new MenuEnum("company_info", "Company Information");
	public static readonly COMPANY_PROFILE = new MenuEnum("company_profile", "Company Profile");
	public static readonly VOU_OTH_CH = new MenuEnum("vou_oth_ch", "Voucher Other Charge");
	public static readonly LEAD_SRC = new MenuEnum("lead_src", "Lead Source");
	public static readonly LEAD_STATUS = new MenuEnum("lead_status", "Lead Status");
	public static readonly IMPORT_DATA = new MenuEnum("import_data", "Import Data");
	public static readonly MENU_CONFIG = new MenuEnum("menu_config", "Menu Config");

	private constructor(
		public readonly key: string,
		public readonly displayName: string) {
	}

	static get values(): MenuEnum[] {
		return [
			this.COMMNC_BOOK,
			this.REMINDER,
			this.COMMNC,
			this.LEAD,
			this.ACCT_BOOK,
			this.DAY_BOOK,
			this.CDP_JOURNAL_VOU,
			this.JOURNAL,
			this.CONTRA,
			this.RECEIPT,
			this.PAYMENT,
			this.SALES_RETURN,
			this.SALES,
			this.PI,
			this.SALES_ORDER,
			this.ESTIMATE,
			this.PURCHASE_RETURN,
			this.PURCHASE,
			this.PURCHASE_ORDER,
			this.COMM_VOU,
			this.CDP_VOU,
			this.ORDER_ADJUST,
			this.BANK,
			this.CUSTOMER,
			this.SUPPLIER,
			this.AGENT,
			this.ACCT,
			this.ACCT_TYPE,
			this.INV_ADJUST,
			this.COMM_LIST,
			this.PURCHASE_PRICE_LIST,
			this.SALES_PRICE_LIST,
			this.SERVICE,
			this.PROD,
			this.PROD_OTH_CH,
			this.TAX_CAT,
			this.CAT,
			this.UNIT,
			this.RPT_SALES_RETURN_BY_MONTH,
			this.RPT_SALES_RETURN_BY_DAY,
			this.RPT_SALES_RETURN_BY_CUSTOMER,
			this.RPT_SALES_RETURN_BY_CATEGORY,
			this.RPT_SALES_RETURN_BY_PRODUCT,
			this.RPT_SALES_RETURN_BY_CITY,
			this.RPT_SALES_RETURN_BY_STATE,
			this.RPT_SALES_RETURN_BY_AGENT,
			this.RPT_SALES_RETURN_DETAILED_BY_VOUCHER,
			this.RPT_SALES_RETURN_DETAILED_BY_PROD,
			this.RPT_SALES_BY_MONTH,
			this.RPT_SALES_BY_DAY,
			this.RPT_SALES_BY_CUSTOMER,
			this.RPT_SALES_BY_CATEGORY,
			this.RPT_SALES_BY_PRODUCT,
			this.RPT_SALES_BY_CITY,
			this.RPT_SALES_BY_STATE,
			this.RPT_SALES_BY_AGENT,
			this.RPT_SALES_DETAILED_BY_VOUCHER,
			this.RPT_SALES_DETAILED_BY_PROD,
			this.SALES_ANALYSIS,
			this.RPT_SALES_PROFIT_BY_CUSTOMER,
			this.RPT_SALES_PROFIT_BY_PROD,
			this.RPT_SALES_PROFIT_BY_SALES_VOUCHER,
			this.RPT_SALES_PROFIT_BY_SALES_ITEM,
			this.RPT_PURCHASE_RETURN_BY_MONTH,
			this.RPT_PURCHASE_RETURN_BY_DAY,
			this.RPT_PURCHASE_RETURN_BY_SUPPLIER,
			this.RPT_PURCHASE_RETURN_BY_CATEGORY,
			this.RPT_PURCHASE_RETURN_BY_PRODUCT,
			this.RPT_PURCHASE_RETURN_BY_CITY,
			this.RPT_PURCHASE_RETURN_BY_STATE,
			this.RPT_PURCHASE_RETURN_DETAILED_BY_VOUCHER,
			this.RPT_PURCHASE_RETURN_DETAILED_BY_PROD,
			this.RPT_PURCHASE_BY_MONTH,
			this.RPT_PURCHASE_BY_DAY,
			this.RPT_PURCHASE_BY_SUPPLIER,
			this.RPT_PURCHASE_BY_CATEGORY,
			this.RPT_PURCHASE_BY_PRODUCT,
			this.RPT_PURCHASE_BY_CITY,
			this.RPT_PURCHASE_BY_STATE,
			this.RPT_PURCHASE_DETAILED_BY_VOUCHER,
			this.RPT_PURCHASE_DETAILED_BY_PROD,
			this.RPT_ESTIMATE_PENDING_BY_PROD,
			this.RPT_ESTIMATE_PENDING_BY_ACCT,
			this.RPT_ESTIMATE_PENDING_PRODUCTS,
			this.DUE_RCVABLE_BY_CUSTOMER,
			this.DUE_RCVABLE_BY_SALES,
			this.RPT_IO_SUMM_BY_PROD_CAT,
			this.RPT_IO_SUMM_BY_MONTH,
			this.RPT_IO_SUMM_BY_PROD,
			this.RPT_IO_DET,
			this.LEDGER,
			this.ACCT_SUMM_BY_ACCT_TYPE,
			this.TRIAL_BAL,
			this.TRADING_ACCT,
			this.PL_ACCT,
			this.BALANCE_SHEET,
			this.GSTR3B,
			this.INVENTORY_LEDGER,
			this.CURRENT_STOCK,
			this.CURRENT_STOCK_VAL,
			this.LOW_STOCK,
			this.CDP_VOU_LEDGER,
			this.CDP_VOU_LEDGER_2,
			this.COMM_VOU_LEDGER,
			this.SALES_ORDER_PENDING,
			this.PURCHASE_ORDER_PENDING,
			this.MSG_QUEUE,
			this.SCHEME,
			this.TRANSPORTER,
			this.TAX_CAL_RATE,
			this.TAX,
			this.MSG_TEMPLATE,
			this.BROWSER,
			this.COMPANY_INFO,
			this.COMPANY_PROFILE,
			this.VOU_OTH_CH,
			this.LEAD_SRC,
			this.LEAD_STATUS,
			this.IMPORT_DATA,
			this.MENU_CONFIG,
		];
	}

	static getMenuByDisplayName(displayName: string): MenuEnum | null {
		this.values.forEach(element => {
			if (element.displayName === displayName) {
				return element;
			}
		});
		return null;
	}

	static getByKey(key: string) {
		let elements = this.values;
		for (let i = 0; i < elements.length; i++) {
			let element = elements[i];

			if (key === element.key)
				return element;
		}
		return null;
	}

}

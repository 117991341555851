import { useEffect, useState } from "react";
import { BASE_URL, TOKEN } from "../../config";
import useConfirm from "../../dialogs/ConfirmDialog";
import MessageDialog from "../../dialogs/MessageDialog";
import { ResourceEnum } from "../../enums/ResourceEnum";
import strFormat from "../../helpers/StringHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import TaxCatListResType from "../../types/res/list/TaxCatListResType";
import ListTaxCatSm from "../../types/sm/ListTaxCatSmType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import { useGvContext } from "../../GvContext";

function ListTaxCat(): JSX.Element {

	const resourceName = ResourceEnum.TAX_CAT.name;

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setselectedId] = useState<number | null>(null);

	const [listTaxCategorySm, setListTaxCategorySm] = useState<ListTaxCatSm>(new ListTaxCatSm());

	const [showSd, setShowSd] = useState<boolean>(false);
	const [sdResolve, setSdResolve] = useState<any>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	const colNames = [
		"Name",
		"Tax",
		"HSN",
		"Created By",
		"Created At",
	];

	useEffect(() => {
		async function process() {
			loadData(listTaxCategorySm);
		}
		process();
	}, []);

	const handleClickAdd = () => {
		gv.menuHelper.openAddTaxCat();
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listTaxCategorySm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listTaxCategorySm: ListTaxCatSm,
		colSettingsLocal?: ColSettingResType[]
	) => {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listTaxCategorySm)
			, requestOptions);
		const results: TaxCatListResType[] = await res.json();

		let dataToReturn: RowType[] = [];
		results.forEach((result) => {
			let oneRowArray: string[] = [];

			oneRowArray.push(result.name);
			oneRowArray.push(result.taxName);
			let hsn: string = "";
			if (result.hsn != null) {
				hsn = result.hsn.toString();
			}
			oneRowArray.push(hsn);
			oneRowArray.push(result.createdByUserName);
			oneRowArray.push(result.createdAt.toString());

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setselectedId}
				/>
			) : null}

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListTaxCat;

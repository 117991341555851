import DropDownItemType from "../types/DropDownItemType";
import TransportModeListResType from "../types/res/list/TransportModeListResType";

export default class TransportModeHelper {

	static getTransportModeListResArrForDd(listTransportModes: TransportModeListResType[]) {

		let finalArr: DropDownItemType[] = [];
		listTransportModes.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getTransportModeListResForDd(transportMode: TransportModeListResType): DropDownItemType {
		return {
			id: transportMode.id,
			name: transportMode.name
		};
	}

	static getTransportModeListResMap(itemArr: TransportModeListResType[]) {
		let map = new Map<number, TransportModeListResType>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
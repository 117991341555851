export class ResourceEnum {

	public static readonly ACCESS_ROLE = new ResourceEnum(1, "access-roles");
	public static readonly ACCT = new ResourceEnum(2, "accounts");
	public static readonly ACCT_TYPE = new ResourceEnum(3, "account-types");
	public static readonly AGENT = new ResourceEnum(4, "agents");
	public static readonly AUTH = new ResourceEnum(5, "auth");
	public static readonly BANK = new ResourceEnum(6, "banks");
	public static readonly CASH_DISC_VOU = new ResourceEnum(7, "cash-discount-vouchers");
	public static readonly CDP_VOU = new ResourceEnum(8, "cash-discount-penalty-vouchers");
	public static readonly COL_SETTING = new ResourceEnum(9, "col-settings");
	public static readonly COMM_LIST = new ResourceEnum(10, "commission-lists");
	public static readonly COMM_VOU = new ResourceEnum(11, "commission-vouchers");
	public static readonly COMMNC = new ResourceEnum(12, "communications");
	public static readonly COMPANY_PROFILE = new ResourceEnum(13, "company-profiles");
	public static readonly CONTRA = new ResourceEnum(14, "contras");
	public static readonly COUNTRY = new ResourceEnum(15, "countries");
	public static readonly CURRENCY = new ResourceEnum(16, "currencies");
	public static readonly CUSTOMER = new ResourceEnum(17, "customers");
	public static readonly ESTIMATE = new ResourceEnum(18, "estimates");
	public static readonly ESTIMATE_STATUS = new ResourceEnum(19, "estimate-statuses");
	public static readonly INV_ADJUST = new ResourceEnum(20, "inventory-adjusts");
	public static readonly JOURNAL = new ResourceEnum(21, "journals");
	public static readonly LEAD = new ResourceEnum(22, "leads");
	public static readonly LEAD_SRC = new ResourceEnum(23, "lead-sources");
	public static readonly LEAD_STATUS = new ResourceEnum(24, "lead-statuses");
	public static readonly LOGIN = new ResourceEnum(25, "login");
	public static readonly MENU = new ResourceEnum(26, "menus");
	public static readonly MSG_TEMPLATE = new ResourceEnum(27, "msg-templates");
	public static readonly ORDER_ADJUST = new ResourceEnum(28, "order-adjusts");
	public static readonly PAYMENT = new ResourceEnum(29, "payments");
	public static readonly PAYMENT_MODE = new ResourceEnum(30, "payment-modes");
	public static readonly PAYMENT_STATUS = new ResourceEnum(31, "payment-statuses");
	public static readonly PAYMENT_TERMS = new ResourceEnum(32, "payment-terms");
	public static readonly PI = new ResourceEnum(33, "proforma-invoices");
	public static readonly PRODUCT = new ResourceEnum(34, "products");
	public static readonly PRODUCT_CAT = new ResourceEnum(35, "product-categories");
	public static readonly PRODUCT_OTH_CHARGE = new ResourceEnum(36, "product-other-charges");
	public static readonly PURCHASE = new ResourceEnum(37, "purchases");
	public static readonly PURCHASE_ORDER = new ResourceEnum(38, "purchase-orders");
	public static readonly PURCHASE_PRICE_LIST = new ResourceEnum(39, "purchase-price-lists");
	public static readonly PURCHASE_RETURN = new ResourceEnum(40, "purchase-returns");
	public static readonly RECEIPT = new ResourceEnum(41, "receipts");
	public static readonly REMINDER = new ResourceEnum(42, "reminders");
	public static readonly SALES = new ResourceEnum(43, "saleses");
	public static readonly SALES_CHANNEL = new ResourceEnum(44, "sales-channels");
	public static readonly SALES_ORDER = new ResourceEnum(45, "sales-orders");
	public static readonly SALES_PRICE_LIST = new ResourceEnum(46, "sales-price-lists");
	public static readonly SALES_RETURN = new ResourceEnum(47, "sales-returns");
	public static readonly SERVICE = new ResourceEnum(48, "services");
	public static readonly STATE = new ResourceEnum(49, "states");
	public static readonly SUPPLIER = new ResourceEnum(50, "suppliers");
	public static readonly TAX = new ResourceEnum(51, "taxes");
	public static readonly TAX_CAL_TYPE = new ResourceEnum(52, "tax-cal-types");
	public static readonly TAX_CAT = new ResourceEnum(53, "tax-categories");
	public static readonly TNC = new ResourceEnum(54, "tncs");
	public static readonly TRADE_TERMS = new ResourceEnum(55, "trade-terms");
	public static readonly TRANSPORT_MODE = new ResourceEnum(56, "transport-modes");
	public static readonly TRANSPORTER = new ResourceEnum(57, "transporters");
	public static readonly UNIT = new ResourceEnum(58, "units");
	public static readonly USER = new ResourceEnum(59, "user");
	public static readonly VOU_OTH_CHARGE = new ResourceEnum(60, "voucher-other-charges");
	public static readonly VOUCHER = new ResourceEnum(61, "vouchers");

	private constructor(
		public readonly id: number,
		public readonly name: string) {
	}

}

import { useEffect, useState } from "react";
import { GvContextProvider } from "../GvContext";
import MenuApi from "../api/MenuApi";
import {
	CURRENT_LAYOUT,
	CURRENT_TITLE, MENU_STACK,
	TITLE_STACK
} from "../config";
import MenuResType from "../types/res/MenuResType";
import Menu from "./Menu";
import Topbar from "./Topbar";
import MenuHelper from "../helpers/MenuHelper";

function Container() {

	const [loading, setLoading] = useState(true);
	// const [menu, setMenu] = useState(null);
	const [componentToRender, setComponentToRender] = useState<JSX.Element | null>(null);
	const [pageTitle, setPageTitle] = useState<string>("Menu");

	const openMenuView = (parentMenu: MenuResType) => {

		if (!(parentMenu.id === 0)) {
			MENU_STACK.push(CURRENT_LAYOUT.val);
			TITLE_STACK.push(CURRENT_TITLE.val);
		}

		CURRENT_LAYOUT.val = (
			<Menu
				menus={parentMenu.menuResDtos}
				openMenuView={openMenuView}
			/>
		);
		CURRENT_TITLE.val =
			parentMenu.displayName === null
				? "Menu"
				: parentMenu.displayName === ""
					? parentMenu.name
					: parentMenu.displayName;

		setPageTitle(CURRENT_TITLE.val);
		setComponentToRender(CURRENT_LAYOUT.val);
	};

	const goBack = () => {
		if (MENU_STACK.length > 0) {
			CURRENT_LAYOUT.val = MENU_STACK.pop()!;
			CURRENT_TITLE.val = TITLE_STACK.pop()!;
			setPageTitle(CURRENT_TITLE.val);
			setComponentToRender(CURRENT_LAYOUT.val);
		}
	};

	const openLayout = (element: JSX.Element, title: string) => {

		MENU_STACK.push(CURRENT_LAYOUT.val);
		TITLE_STACK.push(CURRENT_TITLE.val);

		CURRENT_LAYOUT.val = element;
		CURRENT_TITLE.val = title;

		setPageTitle(CURRENT_TITLE.val);
		setComponentToRender(CURRENT_LAYOUT.val);
	}

	const menuHelper = new MenuHelper(openLayout);

	useEffect(() => {
		async function process() {
			let res = await MenuApi.getMenus();
			if (res.isSuccess) {
				openMenuView(res.item!);
			}
		}
		process();

		window.addEventListener('keydown', handleKeyDown);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);


	// Function to handle keyboard events
	const handleKeyDown = (event: KeyboardEvent) => {
		if (
			event.key === "Escape"
			&& !event.ctrlKey
			&& !event.altKey
			&& !event.metaKey
			&& !event.shiftKey) {
			goBack();
		}
		// Add more conditions for other shortcuts if needed
	};

	// function handleMenuClick(menu) {
	// 	setMenu(menu);
	// }

	// console.log("/* menu");
	// console.log(menu);
	// console.log("menu */");

	return (
		<GvContextProvider value={{ menuHelper: menuHelper }}>
			<div className="d-flex flex-column vh-100">
				<Topbar title={pageTitle} goBack={goBack} />
				{componentToRender}
			</div>
		</GvContextProvider>
	);
}

export default Container;
